
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SystemService } from './system.service';
import { RequestCallback } from '../model/requestcallback';


@Injectable({
    providedIn: 'root'
})
export class RequestCallBackService {
    apiUrl: any;
    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    //Add RequestCallbacks
    async addRequestCallBack(data: RequestCallback): Promise<Observable<RequestCallback>> {
        console.log(data);
        let res = await this.service.Data.ExecuteAPI_Post("Requestcallback", data);
        console.log(res);
        return res;
    }



    //Get All RequestCallbacks
    async getAllRequestCallBack(): Promise<RequestCallback[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<RequestCallback[]>("RequestCallBack");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching RequestCallBack:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }
  //By Id RequestCallbacks
    async getRequestCallBackById(id: number): Promise<RequestCallback> {
        try {
          return await this.service.Data.ExecuteAPI_Get<RequestCallback>("RequestCallBack/" + id);
        } catch (error) {
          // Handle errors here
          console.error('Error fetching RequestCallBack:', error);
          return null; // or throw the error, depending on your error-handling strategy
        }
      }

      async deleteRequestCallBack(id: number) {
        return  await this.service.Data.ExecuteAPI_Delete("RequestCallBack", `${id}?id=${id}`);
    }

}
