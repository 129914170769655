import { GalleryService } from './../../services/gallery.service';
import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../services/category.service';
import { TypeService } from '../../services/type.services';
import { SystemService } from '../../services/system.service';
import { tr } from 'date-fns/locale';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryViewComponent implements OnInit {
    typeGallerylist: any;
    isLoading = false;
    id: any;
    galleryPhoto: any;
    apiPath: any;
    constructor(private sanitizer: DomSanitizer,private galleryService: GalleryService, public service: SystemService, private route: ActivatedRoute) {

        this.route.params.subscribe(params => {
            this.id = params['id'];
            // Now you can use the 'id' in your component logic
        });

    }

    ngOnInit(): void {
        this.apiPath = this.service.Settings.Base_API_URL;
        this.isLoading = true;
        this.loadtypegallerylist(this.id);
        this.startAutoSlide();
    }

    currentIndex = 0;
    interval: any;

    ngOnDestroy() {
      // Stop auto-sliding when component is destroyed
      this.stopAutoSlide();
    }

    // Function to navigate to the next image
    next() {
      this.currentIndex = (this.currentIndex + 1) % this.typeGallerylist.galleryPhoto?.length;
    }

    // Function to navigate to the previous image
    prev() {
      this.currentIndex = (this.currentIndex - 1 + this.typeGallerylist.galleryPhoto?.length) % this.typeGallerylist.galleryPhoto?.length;
    }

    // Function to start auto-sliding
    startAutoSlide() {
      this.interval = setInterval(() => {
        this.next();
      }, 2000); // Change slide every 2 seconds (adjust as needed)
    }

    // Function to stop auto-sliding
    stopAutoSlide() {
      clearInterval(this.interval);
    }

    sanitizeImage(binaryData: string) {
        const imageUrl = binaryData; // adjust MIME type accordingly
        return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
      }


   async loadtypegallerylist(id) {
        this.isLoading = true;
        console.log(this.isLoading);
       await this.galleryService.getGalleryTypeDetails(id).then(typeGallerylist => {
            this.typeGallerylist = typeGallerylist;
            console.log(JSON.stringify(this.typeGallerylist));
        });
        this.isLoading = false;
    }




}
