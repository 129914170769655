import { Component, OnInit } from '@angular/core';
import { SystemService } from '../../services/system.service';

@Component({
  selector: 'app-footer-style-two',
  templateUrl: './footer-style-two.component.html',
  styleUrls: ['./footer-style-two.component.scss']
})
export class FooterStyleTwoComponent implements OnInit {
    userID: string = "";
  constructor( public service: SystemService) { }

  ngOnInit(): void {
    // this.service.HasAccountData.then((data) => {
    //     this.userID = this.service.Account.userId;
    // }, () => { });
  }

}
