<!-- List of Questions -->

<!-- <ul>
    <li *ngFor="let question of questions$">
        {{ question.Identifier }}
    </li>
</ul>

<br>
<hr>
<br>
{{questions$.Identifier | json }}
{{ questions$.Identifier| json }}
{{ questions$ | json }}

<br>
<hr>
<br>
<div *ngFor="let question of questions$">
    <h4>{{ question.QuestionnaireId }}</h4>
    <h4>{{ question.headline }}</h4>
    <p>{{ question.description }}</p>
     <div *ngFor="let choice of question.Choices">
      <input type="checkbox" [(ngModel)]="choice.Selected" /> {{ choice.Label }}
    </div>
</div>

<br>
<hr>
<br>
{{questions$ | json }}

<br>
<hr>
<br>
{{questions$.QuestionType | json }} -->

<!-- <div  *ngFor="let question of questions$; let i = index" class="page_wrapper" [class.focus]="i == 0" [id]="question.Identifier">
    <div class="inner_wrapper">
      <h3>{{question.Headline}}</h3>
      <div *ngIf="question.question_type == 'multiple-choice'">
        <app-single-option [question]="question" *ngIf="question.multiple === 'false'" (scrollNext)="gotoNext($event)"></app-single-option>
        <app-multi-option [question]="question" *ngIf="question.multiple === 'true'" (scrollNext)="gotoNext($event)"></app-multi-option>
      </div>
      <app-text-answer *ngIf="question.question_type == 'text'" [question]="question" (scrollNext)="gotoNext($event)"></app-text-answer>
  </div>
</div>  -->
<section class="process-area process-bg2" style="height: 500px;">
    <div class="process-into pt-100 pb-70">
        <div class="container">
            <div *ngFor="let question of questions$; let i = index" class="page_wrapper" [class.focus]="i == 0"
                [id]="question.identifier">
                <div class="inner_wrapper">
                    <h3>{{ question.headline }}</h3>
                    <div *ngIf="question.questionType === 'multiple-choice'">
                        <app-single-option [question]="question" *ngIf="question.multiple === false"
                            (scrollNext)="gotoNext($event)"></app-single-option>
                        <app-multi-option [question]="question" *ngIf="question.multiple === true"
                            (scrollNext)="gotoNext($event)"></app-multi-option>
                    </div>
                    <app-text-answer *ngIf="question.questionType === 'text'" [question]="question"
                        (scrollNext)="gotoNext($event)"></app-text-answer>
                </div>

            </div>
            <div (click)="backhome()" class="btn btn-success" *ngIf="!questions$">
                If there are forms to fill for this process, click back here.</div>
        </div>
    </div>
</section>
