import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SystemService } from '../../services/system.service';
import { AlertifyService } from '../../services/alertify.service';

@Component({
    templateUrl: './reset_password.html'
})

export class Reset_PasswordComponent {
    isLoading = false; returnUrl: string;
    ResetPasswordForm: FormGroup;
    public key: string;
    showPassword: boolean = false; isPasswordPresent: boolean = false;
    showPassword2: boolean = false; isPasswordPresent2: boolean = false;
    public UserName: string; public Email: string;
    userSubmitted: boolean;

    constructor(private alertify: AlertifyService, public fb: FormBuilder, public route: ActivatedRoute, public router: Router, public service: SystemService, public location: Location) {

        route.queryParams
        .subscribe((params) =>
        {
          console.log(params)
          this.key = params.Key;
          console.log(this.key);
          this.service.ClearToken();
        });
        if (!this.key) {
            this.service.logOut();
        }
        this.initForm();
    }
    ngOnInit() {
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    }



    get email() {
        return this.ResetPasswordForm.get('email') as FormControl;
    }

    get password() {
        return this.ResetPasswordForm.get('password') as FormControl;
    }

    get confirmPassword() {
        return this.ResetPasswordForm.get('confirmPassword') as FormControl;
    }
    async initForm() {

        this.ResetPasswordForm = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
            password: ["", Validators.required],
            confirmPassword: ["", Validators.required]
        }, { validators: this.passwordMatchingValidator });

        console.log("RRR" + this.isPasswordPresent);

    }
    passwordMatchingValidator(fg: FormGroup): Validators {
        return fg.get('password').value === fg.get('confirmPassword').value ? null :
            { notmatched: true }
    }

    togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
    }

    onPasswordChange() {
        const passwordValue = this.ResetPasswordForm.get('password').value;
        console.log(passwordValue);
        this.isPasswordPresent = !!passwordValue;

    }

    togglePasswordVisibility2() {
        this.showPassword2 = !this.showPassword2;
    }
    onPasswordChange2() {
        const passwordValue = this.ResetPasswordForm.get('confirmPassword').value;
        console.log(passwordValue);
        this.isPasswordPresent2 = !!passwordValue;

    }
    async ResetPassword() {
        try {
            if (this.email) {
                this.isLoading = true;
                let obj = this.ResetPasswordForm.getRawValue();
                let res = await this.service.Data.ExecuteAPI_Post("reset-password", { Token: this.key, Email: obj.email, NewPassword: this.ResetPasswordForm.value.password });

                if (res) {
                    this.userSubmitted = false;
                    this.alertify.success(res);
                    this.router.navigate(["/login-register"]);
                }
                else if (res == -1) {
                    this.alertify.success("Username In Correct Contact Administrator");
                }
                else {
                    this.alertify.success("msgError");
                }
                this.isLoading = false;
            }
            else {
                this.alertify.success("Username Not Exists In System");
            }

        } catch (e) {
            this.isLoading = false;
        }
    }

}
