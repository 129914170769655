import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
    selector: 'app-finalpopup',
    templateUrl: './finalpopup.component.html',
    styleUrls: ['./finalpopup.component.scss']
})
export class FinalpopupComponent implements OnInit {

    id: number;
    title: any;
    constructor(public modalRef: BsModalRef,private router: Router) {

    }
    ngOnInit(): void {
        this.id = this.modalRef.content.initialState.id;
    }
    onclick(id: any)
    {
        this.router.navigate(['/listing-details', this.id]);
        this.modalRef.hide()
    }

}
