import { routes } from './../../../app-routing.module';
import { Component, OnInit, Input, ElementRef, EventEmitter, Output, NgZone } from '@angular/core';
import { Choice, Question, QuestionnaireResponse, ResponseModel } from '../../model/question';
import { QuestionnaireService } from '../../services/questionnaire.service';
import { SystemService } from '../../services/system.service';


@Component({
    selector: 'app-text-answer',
    templateUrl: './text-answer.component.html',
    styleUrls: ['./text-answer.component.scss']
})
export class TextAnswerComponent implements OnInit {

    @Input()
    question: QuestionnaireResponse;

    @Output()
    scrollNext = new EventEmitter<any>();

    answer: string = '';
    constructor(private dataService: QuestionnaireService, public service: SystemService, private zone: NgZone) {

    }

    ngOnInit() {
    }


    addToSelectedOptions(option) {
        option.questionnaireId = this.question.QuestionnaireId;
        this.dataService.addDataQuestions(option);
        console.log(JSON.stringify(option));

    }
    /**
     * On Enter key press
     * @param event
     */
    onPressEnter(event) {
        // console.log(JSON.stringify(event));
        // console.log(JSON.stringify(this.question));
        const questionnaireData: QuestionnaireResponse = JSON.parse(JSON.stringify(this.question));
        const idValue: number = questionnaireData.Id;
        const questionnaireIdValue: number = questionnaireData.QuestionnaireId;
        const headline: string = questionnaireData.Headline;
        let userId = localStorage.getItem('id');
        let typeId = localStorage.getItem('typeId');
        this.zone.run(() => {
            // Update the answer with the current value
            this.answer = event.target.value;
        });
        const routesesponseModel: ResponseModel = {
            Id: 0,
            QuestionnaireId: questionnaireIdValue,
            QuestionId: idValue,
            UserId: userId,
            ResponseLabel: headline,
            ResponseValue: this.answer,
            ResponseDate: null,
            TypeId: Number(typeId),
            CreatedDate: null,
            Active: true,
        };
        // console.log(routesesponseModel);
        //console.log(this.answer);
        if (event.keyCode == 13) {
            this.gotoNext();
        }
    }

    /**
     * Go to next
     */
    gotoNext() {
        try {
            if (this.scrollNext) {
                console.log("Checking this.scrollNext:", this.scrollNext);
                this.scrollNext.emit({
                    question: this.question,
                    destination: ''
                });
            } else {
                console.error("this.scrollNext is null or undefined");
            }
        }
        catch
        {
            console.log("Final text");
        }


    }
}
