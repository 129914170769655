import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SystemService } from './components/services/system.service';
import { Observable } from 'rxjs';


@Injectable()
export class AuthGuard implements CanActivate {
    public defered = new Deferred<boolean>();
    public lastURL: string;
    constructor(public router: Router, public service: SystemService) { }
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkAuthentication(state.url);
      }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        this.defered = new Deferred<boolean>();
        this.lastURL = state.url;
        console.log(route.data);
        try {

        this.service.HasAccountData.then(() => {
            window.setTimeout(() => {
                console.log(this.service.Account);
                if (this.service.App.getCookie("Bearer") && this.service.Account.userId.length > 1) {
                    console.log(route.data.isAdminshow);
                    this.defered.resolve(true);
                    console.log("pageProp true " + true);
                }
                else {
                    this.defered.resolve(false);
                    console.log("Redirect To Login--" + this.lastURL);
                    this.service.redirectToLogin(this.lastURL);
                    console.log("redirectToLogin false " + false);
                }
            }, 10);
        }, () => {
            this.defered.resolve(false);
            console.log("Redirect To Login--" + this.lastURL);
            this.service.redirectToLogin(this.lastURL);
            console.log(" Exit redirectToLogin false " + false);
        });
        }
        catch (error)
        {
            this.defered.resolve(false);
            console.log("Redirect To Login--" + this.lastURL);
            this.service.redirectToLogin(this.lastURL);
            console.log(" Exit redirectToLogin false " + false);
       }
        console.error("details:", this.defered.promise);
        // Logging the promise details when it settles
        this.defered.promise.then(
            resolvedValue => console.log("Promise resolved:", resolvedValue),
            rejectedReason => console.log("Promise rejected:", rejectedReason)
        );
        return this.defered.promise;
    }

    private async checkAuthentication(url: string): Promise<boolean> {
        try {
          await this.service.HasAccountData;
          await new Promise(resolve => setTimeout(resolve, 10)); // Simulating a delay, adjust as needed

          if (this.service.App.getCookie("Bearer") && this.service.Account.userId.length > 1) {
            console.log("User is authenticated");
            return true;
          } else {
            console.log("User is not authenticated, redirecting to login");
            this.service.redirectToLogin(url);
            return false;
          }
        } catch (error) {
          console.error("Error checking authentication:", error);
          this.service.redirectToLogin(url);
          return false;
        }
      }
}



class Deferred<T> {

    promise: Promise<T>;
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;

    constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}
