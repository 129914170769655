import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemService } from './system.service';
import { Gallery } from '../model/gallery';


@Injectable({
    providedIn: 'root'
})
export class GalleryService {
    getCategoryNameById(id: number) {
        throw new Error('Method not implemented.');
    }
    private apiPath: string;
    constructor(private http: HttpClient, public service: SystemService) { this.apiPath = this.service.Settings.Base_API_URL }


    async addGallery(Gallery: Gallery) {
        Gallery.GalleryId = 0;
        let res = await this.service.Data.ExecuteAPI_Post("Gallery", Gallery);
        console.log(res);
        return res;
    }


    async getGallery(id: number): Promise<any> {
        try {
          return await this.service.Data.ExecuteAPI_Get<any>("Gallery/{id}?galleryid=" + id);
        } catch (error) {
          console.error('Error fetching categories:', error);
          return null;
        }
      }

      async getGalleryTypeDetails(id: number): Promise<any> {
        try {
          return await this.service.Data.ExecuteAPI_Get<any>("Gallery/typeDetails/{id}?typeId=" + id);
        } catch (error) {
          console.error('Error fetching categories:', error);
          return null;
        }
      }

    async getAllGallerys(){
        //return this.http.get<any>(this.apiPath + '/api/Gallery');
        try {
            return await this.service.Data.ExecuteAPI_Get<any>("Gallery");
          } catch (error) {
            console.error('Error fetching Gallerylist:', error);
            return [];
          }
    }

    getUserGallerys(userName: string) {
        return this.http.get<Gallery[]>(this.apiPath + '/api/Gallery/userGallery/' + userName);
    }

    async updateGallery(Gallery: any) {
        let res = await this.service.Data.ExecuteAPI_Put("Gallery",`${Gallery.GalleryId}?galleryId=${Gallery.GalleryId}`, Gallery);
        console.log(res);
        return res;
    }

    deleteGallery(id: number) {
        return this.http.delete(this.apiPath + '/api/Gallery/delete/' + `${id}?galleryId=${id}`);
    }
}
