<!-- <div class="video-container">
    <video id="video-bg" class="video-js vjs-default-skin" autoplay muted loop>
      <source src="../../../../assets/video/play.mp4" type="video/mp4">
      Your browser does not support the video tag.
    </video>
     Add your content here
    <div class="content">
      <h1>Your Website Title</h1>
      <p>Your website content goes here.</p>
    </div>
  </div> -->

<!-- <div class="slider-area owl-carousel owl-theme">
    <div class="slider-item item-bg2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="specific-container">
                    <ngx-youtube-player [youtubeId]="'URKnE8mlWbY'" [height]="600" [width]="1080" [autoPlay]="true"
                        [hideControls]="true" [autoFullscreen]="true" (readyChange)="onReadyChange($event)"
                        #youtubePlayerContainer>
                        <img class="cover" [ngClass]="{hide: isReady}"
                            src="https://i.ytimg.com/vi/URKnE8mlWbY/maxresdefault.jpg" alt="">
                    </ngx-youtube-player>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="slider-area owl-carousel owl-theme">
    <div class="slider-item">
        <video autoplay muted loop playsinline class="video-fluid">
            <source src="../../../../assets/video/1 (2).mp4" type="video/mp4">
            Your browser does not support the video tag.
            <img src="../../../../assets/img/Abou1tus.jpg" alt="Fallback Image">
        </video>
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1 >Find Best The University <b>In Your Choose</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="slider-item">
        <video autoplay muted loop playsinline class="video-fluid">
            <source src="../../../../assets/video/1 (1).mp4" type="video/mp4">
            Your browser does not support the video tag.
            <img src="../../../../assets/img/Abou1tus.jpg" alt="Fallback Image">
        </video>
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1 >Explore the best Jobs <b>Keep Choose Best</b></h1>

                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="slider-item">
        <img src="../../../../assets/img/background/1 (3).jpeg" alt="Fallback Image" class="video-fluid">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1 >Discover The Exact Event <b>Contact Us</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item">
        <img src="../../../../assets/img/background/1 (2).jpeg" alt="Fallback Image" class="video-fluid">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1 >Find The Amazing Courses <b>Explore with us</b></h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="banner-form-area banner-form-mt">
    <div class="container">
        <!-- <div class="form-list text-center">
                <ul>
                    <li class="active"><i class="flaticon-curve-arrow"></i> VISAS</li>
                    <li><i class="flaticon-curve-arrow"></i> RESTAURANTS</li>
                    <li><i class="flaticon-curve-arrow"></i> PACKAGES</li>
                    <li><i class="flaticon-curve-arrow"></i> HOTELS</li>
                </ul>
            </div>-->
        <div class="form-list text-center"><br><br></div>
        <div class="banner-form border-radius">
            <form>
                <div class="row">
                    <div class="col-lg-2 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-place'></i>
                            <input class="form-control" type="text" placeholder="Location">
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-vision'></i>
                            <input class="form-control" type="text" placeholder="What are looking for?">
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4">
                        <div class="form-group">
                            <i class='flaticon-category'></i>
                            <select class="form-control">
                                <option>All Categories</option>
                                <option>Career counseling</option>
                                <option>Admission guidance</option>
                                <option>Job assistance</option>
                                <option>VISA processing </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-2 col-sm-6 offset-sm-3 offset-lg-0">
                        <button type="submit" class="default-btn border-radius">Search <i
                                class="flaticon-loupe"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<section class="category-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/listing"><i class="flaticon-diamond"></i></a>
                    <h3><a routerLink="/listing">Career counseling</a></h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/listing"><i class="flaticon-hotel"></i></a>
                    <h3><a routerLink="/listing">College Portfolio</a></h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/listing"><i class="flaticon-event"></i></a>
                    <h3><a routerLink="/listing">Admission guidance</a></h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/listing"><i class="flaticon-verify"></i></a>
                    <h3><a routerLink="/listing">Job assistance</a></h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/listing"><i class="flaticon-verify"></i></a>
                    <h3><a routerLink="/listing">Financial solutions</a></h3>
                </div>
            </div>
            <div class="col-lg-2 col-sm-4">
                <div class="category-box-card">
                    <a routerLink="/listing"><i class="flaticon-buildings"></i></a>
                    <h3><a routerLink="/listing">VISA processing </a></h3>
                </div>
            </div>


        </div>
    </div>
</section>



<!-- <section class="process-area process-bg2">
    <div class="process-into pt-100 pb-70">
        <div class="container">
            <div class="section-title text-center">
                <span>Process</span>
                <h2>See <b>How It Works</b></h2>
                <p>Experience seamless visa application with our top-notch services</p>
            </div>
            <div class="section-title text-center">
                <br>
            </div>
            <div class="row pt-45">
                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number1">1</div>
                        <i class="flaticon-position"></i>
                        <div class="content">
                            <h3>Explore Options with Confidence</h3>
                            <p style="text-align: justify;font-size: small;">Helping International Students Understand &
                                Navigate The European university Admissions Process
                                At work bee Education and Job consultants are committed to offering practical, expert guidance
                                throughout your admissions journey. We understand that different countries have varying
                                higher education admissions processes, which is why our experts take time to ensure
                                you’re familiar with admissions protocols and requirements. We’re passionate about
                                supporting you throughout the entire admissions lifecycle, from school selection and
                                admissions essay building and editing to interview prep and beyond. </p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6">
                    <div class="process-item">
                        <div class="process-item-number number2 active">2</div>
                        <i class="flaticon-to-do-list"></i>
                        <div class="content">
                            <h3>Effortless Application Process</h3>
                            <p style="text-align: justify;font-size: small;">Personal Statements And Admissions Essays
                                Every international student we work with has diverse backgrounds, interests,
                                experiences, and perspectives. Our student-centric approach to personal statement and
                                admissions essay creation and editing places your unique anecdotes and qualities at the
                                forefront of every application narrative. Our proprietary persona assessment helps us
                                determine your most impactful experiences that build a framework for detailed,
                                compelling essays and more.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                    <div class="process-item">
                        <div class="process-item-number number3">3</div>
                        <i class="flaticon-box"></i>
                        <div class="content">
                            <h3>Brainstorming And Editing Sessions</h3>
                            <p style="text-align: justify;font-size: small;">Personal Statements And Admissions Essays
                                Expert Admissions Interview Coaching & Feedback For International Students
                                Our admissions consultants are higher education interview experts dedicated to helping
                                international students build and hone their skills. Mock interview simulations modeling
                                real interview processes at top-ranked institutions give you the practice necessary to
                                ace admissions interviews. Your counselor will provide detailed feedback so you can
                                improve your answers, finetune your delivery, and boost your performance.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="process-line-2"><img src="assets/img/shape/process-line2.png" alt="Images"></div>
    </div>
</section> -->

<section class="place-list-area pb-70">
    <div class="container-fluid">
        <div class="section-title text-center">
            <br>
        </div>
        <div class="section-title text-center">
            <br>
        </div>
        <div class="section-title text-center">
            <span>Services Lists</span>
            <h2>Explore the Services,</h2>
            <p>Career , College , Admission and Jobs information</p>
        </div>
        <div *ngIf="isLoading">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="section-title text-center">
                        <span>Please wait, We're loading some exciting content for you</span>
                    </div>
                    <div class="spinner" style="background-color: var(--mainColor);"></div>
                </div>
            </div>
        </div>
        <div class="card-container2" card-slider *ngIf="typeCardlist">
            <div class="card2" *ngFor="let obj of typeCardlist">
                <div class="place-list-item">
                    <div class="images">
                        <a [routerLink]="'/listing-details/' + obj.typeId" class="images-list"><img
                                [src]="this.apiPath + '/Documents/appImages/' + obj.bannerPhoto" alt="Images"></a>
                        <div class="place-status bg-dark-orange">
                            <a [routerLink]="'/listing-details/' + obj.typeId">
                                <h3>Know more</h3>
                            </a>
                        </div>
                    </div>
                    <div class="content">
                        <a [routerLink]="'/listing-details/' + obj.typeId">
                            <h3>{{ obj.typeTitle }}</h3>
                        </a>
                       <span>{{ obj.categoryNames }} </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 text-center mt-3">
            <a routerLink="/listing" class="default-btn border-radius">View all Services<i class='bx bx-plus'></i></a>
        </div>
    </div>

</section>



<section class="city-area ptb-100">
    <div class="container">
        <div class="section-title text-center">
            <span>Blog</span>
            <h2>Explore our Blog</h2>
            <p>Career , College , Admission and Jobs information</p>
        </div>
        <div *ngIf="!posts">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="section-title text-center">
                        <span>Please wait, We're loading some exciting content for you</span>
                    </div>
                    <div class="spinner" style="background-color: var(--mainColor);"></div>
                </div>
            </div>
        </div>
        <div class="row pt-45">
            <div [class]="i % 2 === 0 ? 'col-lg-7 col-md-7' : 'col-lg-5 col-md-5'"
                *ngFor="let post of posts; let i = index">
                <div class="city-item">
                    <a routerLink="/blog-details-user-view/{{post.id}}" class="city-img"><img
                            [src]="this.apiPath + '/Documents/appImages/' + post.bannerImage" alt="Images"></a>
                    <div class="city-content">
                        <i class="flaticon-buildings"></i>
                        <div class="city-text">
                            <a routerLink="/blog-details-user-view/{{post.id}}" style="
                            background: black;
                            border-radius: 15px;
                            border: 1px solid #006ec5;
                            padding: 2px 20px 2px 20px; margin-bottom: 10px;">
                                <p>{{post.title}}</p>
                            </a>
                            <p routerLink="/blog-details-user-view/{{post.id}}" style="
                            background: black;
                            border-radius: 15px;
                            border: 1px solid #006ec5;
                            padding: 1% 4%;
                            font-size: 0.8rem;width: 120px;">Read More</p>
                        </div>
                    </div>
                    <h3 class="city-location" style="
                    background: black;
                    border-radius: 15px;
                    border: 1px solid #006ec5;
                    padding: 1% 4%;
                    font-size: 1.2rem;">{{ getCategoryNames(post.category) }}</h3>
                </div>
            </div>
            <div class="col-lg-12 text-center mt-3">
                <a routerLink="/blog" class="default-btn border-radius">View all Blogs<i class='bx bx-plus'></i></a>
            </div>
        </div>
    </div>
</section>

<div class="video-area video-area-bg">
    <div class="container">
        <div class="video-content">
            <h2>Are You Ready To Start Your Journey?</h2>
            <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="play-btn"><i class='bx bx-play'></i></a>
        </div>
    </div>
</div>

<!-- <div class="counter-area">
    <div class="container">
        <div class="counter-bg">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>1254</h3>
                        <span>New Visiters Every Week</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>23165</h3>
                        <span>New Visiters Every Day</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>4563</h3>
                        <span>Won Amazing Awards</span>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-counter">
                        <h3>880</h3>
                        <span>New Listing Every Week</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 -->



<!-- <section class="place-list-three pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Places Lists</span>
            <h2>Latest <b>Listings Added</b></h2>
            <p>Porem ipsum dolor sit ame consectetur adipisicing eli sed usmod tempor </p>
        </div>
    </div>

    <div class="container-fluid">
        <div class="place-slider-three  owl-carousel owl-theme pt-45">
            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list1.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>By, Alfred</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Restaurant</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Billiard Restaurant</h3></a>
                    <p><i class="flaticon-cursor"></i> Dorente rio, 104, 00184 Crono, Canada</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.9</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list2.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile2.png" alt="Images">
                        <h3>By, Jaein</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"> <a routerLink="/listing-details">Beauty Shop</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Beauty Shop</h3></a>
                    <p><i class="flaticon-cursor"></i> Davisto Laterani, 104, 00184 Roma, Italy</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">5.0</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list3.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile3.png" alt="Images">
                        <h3>By, Normand</h3>
                    </div>

                    <div class="place-status bg-color-blue">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/listing-details"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/listing-details"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Fitness Club</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>Ridge Fitness Club</h3></a>
                    <p><i class="flaticon-cursor"></i> Ke visto Onterio, 104, 6789 Rcona, usa</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.5</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list4.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile4.png" alt="Images">
                        <h3>By, Olfred</h3>
                    </div>

                    <div class="place-status bg-color-blue">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Hotel</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Hotel</h3></a>
                    <p><i class="flaticon-cursor"></i> Ostapin, 134, 00184 Coventry, London</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.7</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="place-list-item">
                <div class="images">
                    <a routerLink="/listing-details" class="images-list"><img src="assets/img/place-list/place-list1.jpg" alt="Images"></a>

                    <div class="place-profile">
                        <img src="assets/img/place-list/place-profile.png" alt="Images">
                        <h3>By, Alfred</h3>
                    </div>

                    <div class="place-status bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="place-tag">
                        <ul>
                            <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                            <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                        </ul>
                        <span><a routerLink="/">$$$</a></span>
                        <h3 class="title"><a routerLink="/listing-details">Restaurant</a></h3>
                    </div>
                </div>

                <div class="content">
                    <a routerLink="/listing-details"><h3>The Billiard Restaurant</h3></a>
                    <p><i class="flaticon-cursor"></i> Dorente rio, 104, 00184 Crono, Canada</p>
                    <ul class="place-rating">
                        <li><a routerLink="/">4.9</a></li>
                        <li>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star icon-color'></i>
                            <i class='bx bxs-star icon-color'></i>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->



<!-- <div class="counter-area-three">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">12+</h3>
                    <span>Families</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">800+</h3>
                    <span>Students</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">80+</h3>
                    <span>Business</span>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="single-counter">
                    <h3 class="text-color-white">100 (INDCHAM)</h3>
                    <span>Companies</span>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="place-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-6">
                <div class="section-title mb-45">
                    <span>Desire Places</span>
                    <h2>Most <b>Popular Places</b></h2>
                </div>
            </div>

            <div class="col-lg-5 col-md-6">
                <div class="place-btn">
                    <a routerLink="/listing" class="default-btn">Check out all places <i class='bx bx-plus'></i></a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area1.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.9</a></li>
                            <li>
                                <span>23 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile.png" alt="Images">
                            <h3>By, Lowis Jelda</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> Dongo 184 Crono, Canada</span>
                        <a routerLink="/listing-details"><h3>Denisto Centin Restaurant </h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"> <a routerLink="/">$560-890</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area2.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>30 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-blue">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile2.png" alt="Images">
                            <h3>By, Austin Deli</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 40 Square Plaza, NJ, USA</span>
                        <a routerLink="/listing-details"><h3>Iconic Cafe in Ontario</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$500-700</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area3.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.9</a></li>
                            <li>
                                <span>23 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-heliotrope">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile3.png" alt="Images">
                            <h3>By, Polin Osto</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 34-42 Montgomery St , NY, USA</span>
                        <a routerLink="/listing-details"><h3>Strong body Gym</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/listing-details">$400-800</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area4.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">4.8</a></li>
                            <li>
                                <span>16 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                                <i class='bx bxs-star i-color'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-dark-orange">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile4.png" alt="Images">
                            <h3>By, Debit Jhon</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 27th Brooklyn New York, USA</span>
                        <a routerLink="/listing-details"><h3>Family Convenience Store</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$560-890</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area5.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>16 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-blue">
                        <a routerLink="/listing-details"><h3>Closed Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile5.png" alt="Images">
                            <h3>By, Kelvin Sasi</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 56 Street Square Plaza, NJ, USA</span>
                        <a routerLink="/listing-details"><h3>Iconic Cafe in Onterio</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$300-600</a></h3>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="place-card">
                    <a routerLink="/listing-details" class="place-images"><img src="assets/img/place-area/place-area6.jpg" alt="Images"></a>

                    <div class="rating">
                        <ul>
                            <li><a routerLink="/">5.0</a></li>
                            <li>
                                <span>39 Review</span>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                    </div>

                    <div class="status-tag bg-color-green">
                        <a routerLink="/listing-details"><h3>Open Now</h3></a>
                    </div>

                    <div class="content">
                        <div class="content-profile">
                            <img src="assets/img/place-area/place-area-profile6.png" alt="Images">
                            <h3>By, Creiun Hitler</h3>
                        </div>
                        <span><i class="flaticon-cursor"></i> 34-42 Montgomery St , NY, USA</span>
                        <a routerLink="/listing-details"><h3>Kentorin Hotel</h3></a>
                        <p>Lorem ipsum dolor sit amet, consectetur  quam quaerat voluptatem.</p>
                        <div class="content-tag">
                            <ul>
                                <li><a routerLink="/"><i class="flaticon-place"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-like"></i></a></li>
                                <li><a routerLink="/"><i class="flaticon-workflow"></i></a></li>
                            </ul>
                            <h3 class="price"><a routerLink="/">$400-800</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="client-area">
    <div class="container">
        <div class="client-bg">
            <div class="client-slider owl-carousel owl-theme">
                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Oli Rubion</h3>
                                <span>Rubion Inc</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Sanaik Tubi</h3>
                                <span>Arbon Restaurant</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="client-item">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="client-img">
                                <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="client-content">
                                <h3>Mashrof Ruin</h3>
                                <span>Pice Cafe</span>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing  sit ut fugit sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="application-area-two">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <div class="application-content">
                    <div class="section-title">
                        <span>Download app</span>
                        <h2>Get More In Our Application Sit Back And Enjoy</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit sed do eusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. </p>
                    </div>

                    <div class="application-btn">
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/google-play.svg" alt="svg">
                            <div class="btn-content">
                                <span>ANDROID APP ON</span>
                                <h3>Google Play</h3>
                            </div>
                        </a>
                        <a href="#" class="application-play-btn">
                            <img src="assets/img/company.svg" alt="svg">
                            <div class="btn-content">
                                <span>DOWNLOAD ON THE</span>
                                <h3>App Store</h3>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="application-img-two">
                    <img src="assets/img/mobile2.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div> -->
