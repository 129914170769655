<app-navbar-style-guest></app-navbar-style-guest>



<div class="inner-banner inner-bg2"
    [ngStyle]="{'background-image': 'url(' + this.apiPath + '/Documents/appImages/' + bannerPhoto + ')'}">
    <div *ngIf="isLoading" class="overlay-inner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="spinner" style="background-color: var(--mainColor);"></div>
            </div>
        </div>
    </div>
    <div class="container" *ngFor="let obj of typeCardlist">
        <div class="inner-content">
            <div class="row align-items-center">
                <div class="col-lg-8">
                    <div class="inner-banner-text">
                        <h3> {{ obj.typeTitle }}</h3>
                        <ul>
                            <li><i class="flaticon-cursor"></i>{{obj.location}} {{obj.city}} {{obj.state}}
                                {{obj.country}}</li>
                            <li><i class="flaticon-telephone"></i> <a href="tel:+6(567)876543">{{obj.phone}}</a></li>
                            <li><i class="flaticon-email-1"></i> <a href="#">{{obj.email}}</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="banner-rating">
                        <ul>
                            <li class="rating-star">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <span>{{obj.rating}} Review</span>
                            </li>
                            <li><a routerLink="/listing">{{obj.rating}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-bottom">
            <div class="row">
                <div class="col-lg-6 col-md-6">
                    <div class="banner-tag">
                        <ul>
                            <!-- <li><a routerLink="/listing-details"><i class="flaticon-favorite"></i>Book Now</a></li> -->
                            <!-- <li><a routerLink="/listing" class="button"><i class="flaticon-writing"></i> Add Review</a>
                            </li> -->
                            <!-- Disable "Add Review" button if hasJob is true -->
                            <li *ngIf="!hasJob"><a [routerLink]="['/applynow2/', obj.typeId]" class="button"><i
                                        class="flaticon-writing"></i>Apply Now</a></li>

                            <!-- Disable "Apply Now" button if hasJob is false -->
                            <li *ngIf="hasJob"><a [routerLink]="['/applynow2/', obj.typeId]" class="button"><i
                                        class="flaticon-writing"></i>Apply Now</a></li>
                            <!-- <li><a [routerLink]="['/scheduleCall']" class="button"><i
                                        class="flaticon-telephone"></i>Schedule Call</a></li> -->
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-6">
                    <div class="banner-profile">
                        <!-- <img src="assets/img/place-list/place-profile.png" alt="Images"> -->
                        <!-- <h3>By, Alfred</h3> -->
                        <div class="banner-status">
                            <a routerLink="/listing">
                                <h3>{{obj.openClose}}</h3>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7">
                    <ul class="list">
                        <li><a href="/">Home</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">{{obj.typeTitle}}</li>
                    </ul>
                </div>

                <!-- <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="listing-details-area pt-25 pb-70" [style.height.px]="typeCardlist ? null : 500">
    <div class="container" *ngFor="let obj of typeCardlist">
        <div class="row">
            <div class="col-lg-8">
                <div class="listing-details-wrap" *ngIf="headFeaturesArray">
                    <div class="listing-category">
                        <ul>
                            <li *ngFor="let value of headFeaturesArray"><a routerLink="/listing"><i
                                        class="flaticon-touch"></i>{{value}}</a></li>
                        </ul>
                        <h3><a [href]="obj.map" target="_blank"></a><i class="flaticon-worldwide"></i>MAP</h3>
                    </div>
                    <div class="listing-widget listing-widget-desc">
                        <h3 class="title">{{obj.typeTitle}}</h3>
                        <p [innerHTML]="obj.description"></p>
                        <a href="{{ obj.website }}" class="default-btn border-radius" target="_blank">Visit Website </a>
                        <!-- <i class='bx bx-plus'></i> -->
                        <div class="listing-features" *ngIf="obj.listingFeatures">
                            <h3 class="features-title">Listing Features</h3>
                            <div class="row">
                                <div class="col-lg-3 col-md-3" *ngFor="let value of listingFeaturesArray">
                                    <div class="features-card">
                                        <h3>{{ value }}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <app-gallery></app-gallery>
                    <div class="listing-widget listing-map" *ngIf="obj.mapLink?.length > 0">
                        <h3 class="title">Map Location</h3>
                        <div [innerHtml]="urlSafe"></div>
                    </div>
                    <!-- <div class="listing-widget">
                        <h3 class="title">Item Reviews - 2</h3>

                        <div class="listing-widget-review">
                            <ul>
                                <li class="rating-star">
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <i class='bx bxs-star'></i>
                                    <span>Good Review</span>
                                </li>
                                <li><a routerLink="/listing-details">4.5</a></li>
                            </ul>
                        </div>

                        <div class="progressbar-area">
                            <div class="single-progressbar">
                                <h3>Quality</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-1">5.0</div>
                                    <div class="progressbar-circle pc-1"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Location</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-2">5.5</div>
                                    <div class="progressbar-circle pc-2"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Price</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-3">4.7</div>
                                    <div class="progressbar-circle pc-3"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Service</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-4">4.5</div>
                                    <div class="progressbar-circle pc-4"></div>
                                </div>
                            </div>
                        </div>

                        <div class="listing-comment">
                            <ul>
                                <li>
                                    <img src="assets/img/place-list/listing-details-pro1.png" alt="Image">
                                    <div class="content">
                                        <h3>Devit Killer</h3>
                                        <span>Jnauary 12, 2020</span>
                                        <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                                        <div class="comment-rating">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <span>4.5</span>
                                        </div>
                                        <a routerLink="/listing-details">Reply</a>
                                        <div class="view-list">
                                            Helpful review23
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <img src="assets/img/place-list/listing-details-pro2.png" alt="Image">
                                    <div class="content">
                                        <h3>Morah Jein </h3>
                                    <span>July 12, 2020</span>
                                    <p>Software hack from an initial feasibility study, continuing through l implna business you have to be But we know there's a better</p>
                                        <div class="comment-rating">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <span>4.5</span>
                                        </div>
                                        <a routerLink="/listing-details">Reply</a>
                                        <div class="view-list">
                                            Helpful review23
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div> -->
                    <!--
                    <div class="listing-widget progressbar-area-title">
                        <h3 class="title">Add Review</h3>

                        <div class="progressbar-area progressbar-bg">
                            <div class="single-progressbar">
                                <h3>Quality</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-1">5.0</div>
                                    <div class="progressbar-circle pc-1"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Location</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-2">5.5</div>
                                    <div class="progressbar-circle pc-2"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Price</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-3">4.7</div>
                                    <div class="progressbar-circle pc-3"></div>
                                </div>
                            </div>

                            <div class="single-progressbar">
                                <h3>Service</h3>

                                <div class="progressbar-count">
                                    <div class="progressbar-number pn-4">4.5</div>
                                    <div class="progressbar-circle pc-4"></div>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="listing-widget-contact">
                        <div class="listing-contact-title">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="listing-widget-mail">
                                        <i class="flaticon-email-2"></i>
                                        <div class="content">
                                            <h3>Don’t Worry</h3>
                                            <span>Your email is safe with us.</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6 text-end">
                                    <div class="listing-contact-review">
                                        <ul>
                                            <li class="rating-star">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <span>Your Score</span>
                                            </li>
                                            <li><a href="#">4.5</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="contact-form">
                            <form id="contactForm">
                                <div class="row">
                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" name="name" id="name" class="form-control" required
                                                placeholder="Your Name*">
                                        </div>
                                    </div>

                                    <div class="col-lg-6 col-sm-6">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="email" name="email" id="email" class="form-control" required
                                                placeholder="E-mail*">
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea name="message" class="form-control" id="message" cols="30"
                                                rows="8" required placeholder="Your Message"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn border-radius">Submit Comment <i
                                                class='bx bx-plus'></i></button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="listing-details-side">
                    <div class="listing-widget-side">
                        <h3 class="title">Request call back</h3>

                        <div class="listing-Booking-form">
                            <form [formGroup]="requestForm" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-user'></i>
                                            <input type="text" formControlName="yourName" class="form-control" required
                                                placeholder="Your Name*">
                                            <ng-container *ngIf="requestForm   && requestForm.get('yourName')">
                                                <div
                                                    *ngIf="requestForm.get('yourName').hasError('required') && requestForm.get('yourName').touched">
                                                    Your Name is required.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='flaticon-email-2'></i>
                                            <input type="email" formControlName="email" class="form-control" required
                                                placeholder="E-mail*">
                                            <ng-container *ngIf="requestForm  && requestForm.get('email')">
                                                <div
                                                    *ngIf="requestForm.get('email').hasError('required') && requestForm.get('email').touched">
                                                    Email is required.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class='bx bx-phone'></i>
                                            <input type="text" formControlName="phoneNo" required class="form-control"
                                                placeholder="Your Phone">
                                            <ng-container *ngIf="requestForm && requestForm.get('phoneNo')">
                                                <div
                                                    *ngIf="requestForm.get('phoneNo').hasError('required') && requestForm.get('phoneNo').touched">
                                                    PhoneNo is required.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <i class="flaticon-calendar"></i>
                                            <input type="text" formControlName="dateTime" required class="form-control"
                                                placeholder="Date & Time">
                                            <ng-container *ngIf="requestForm && requestForm.get('dateTime')">
                                                <div
                                                    *ngIf="requestForm.get('dateTime').hasError('required') && requestForm.get('dateTime').touched">
                                                    DateTime is required.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <textarea formControlName="yourMessage" class="form-control" cols="30"
                                                rows="8" required placeholder="Your Message"></textarea>
                                            <ng-container *ngIf="requestForm && requestForm.get('yourMessage')">
                                                <div
                                                    *ngIf="requestForm.get('yourMessage').hasError('required') && requestForm.get('yourMessage').touched">
                                                    Message is required.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <!-- <div class="add-counter">
                                        <h3>Additional Service <br> 01</h3>

                                        <div class="form-input-counter">
                                            <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                            <input type="text" min="1" value="1">
                                            <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                        </div>
                                    </div>

                                    <div class="add-counter">
                                        <h3>Additional Service <br>01</h3>

                                        <div class="form-input-counter">
                                            <span class="minus-btn"><i class='bx bx-minus'></i></span>
                                            <input type="text" min="1" value="1">
                                            <span class="plus-btn"><i class='bx bx-plus'></i></span>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="col-lg-12 col-md-12 text-center">
                                    <button type="submit" class="default-btn border-radius">Send Message <i
                                            class='bx bx-plus'></i></button>
                                </div>
                            </form>



                            <!-- <div class="col-lg-12 col-md-12 text-center">
                                <button type="submit" class="default-btn border-radius">Send Message <i class='bx bx-plus'></i></button>
                            </div> -->
                        </div>
                    </div>

                    <!-- <div class="listing-widget-side">
                        <h3 class="title">Working Hours</h3>

                        <ul class="listing-widget-list">
                            <li>Monday <span>9:00 AM - 8:00 PM</span></li>
                            <li>Sunday <span>Open all day</span></li>
                            <li>Saturday <span>7:00 AM - 9:00 PM</span></li>
                            <li>Friday <span>Open all day</span></li>
                            <li>Wednesday <span>9:00 AM - 8:00 PM</span></li>
                            <li>Tuesday <span>Open all day</span></li>
                            <li>Monday <span>Open all day</span></li>
                        </ul>
                    </div> -->
                    <div class="listing-widget-side listing-blog-post">
                        <h3 class="title">Recent List</h3>
                        <ul class="listing-widget-post">
                            <li *ngFor="let obj of typeCardlist"><br>
                                <div class="img">
                                    <a [routerLink]="'/listing-details/' + obj.typeId"><img
                                            [src]="this.apiPath + '/Documents/appImages/' + obj.bannerPhoto"
                                            alt="Images" width="75" height="100"></a>
                                </div>
                                <div class="content">
                                    <h4>{{obj.typeTitle}}</h4>
                                    <p><i class="flaticon-cursor"></i>{{obj.location}}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="listing-widget-side">
                        <h3 class="title">Contact</h3>

                        <ul class="listing-widget-list">
                            <li>Address : <span> {{obj.address}}</span></li>
                            <br>
                            <br>
                            <li>Phone : <span><a href="{{obj.phone}}"> {{obj.phone}}</a></span></li>
                            <li>Mail : <span><a href="mailto:{{obj.email}}">{{obj.email}}</a></span></li>
                            <li>Website : <span> <a href="#">{{obj.website}}</a></span></li>
                        </ul>
                        <!-- <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul> -->
                    </div>

                    <!-- <div class="listing-widget-side">
                        <h3 class="title">Price Range</h3>

                        <h3 class="price-title">Price : <span>$ 67.00 - $ 390.00</span></h3>
                    </div> -->

                    <!-- <div class="listing-widget-side">
                        <h3 class="title">Hosted by :</h3>

                        <div class="listing-side-host">
                            <img src="assets/img/place-list/listing-details-pro3.png" alt="Images">
                            <h3>Jhon Kelvin</h3>
                            <span>20 PLACES HOSTED</span>
                        </div>

                        <ul class="side-host-list">
                            <li><a href="#"><i class='bx bxs-user'></i></a></li>
                            <li><a href="#"><i class='bx bx-envelope'></i></a></li>
                            <li> <span>Claim Now!</span></li>
                        </ul>
                    </div> -->

                    <!-- <div class="listing-widget-side">
                        <h3 class="title">News Tags</h3>

                        <ul class="listing-side-tag">
                            <li><a href="#">#Home</a></li>
                            <li><a href="#"><b>#Residence</b></a></li>
                            <li><a href="#">#Real estate</a></li>
                            <li><a href="#"><b>#Residence</b></a></li>
                            <li><a href="#">#Fancyhouse</a></li>
                            <li><a href="#">#Real estate</a></li>
                        </ul>
                    </div> -->

                    <!-- <div class="listing-widget-side listing-blog-post">
                        <h3 class="title">News Tags</h3>

                        <ul class="listing-widget-post">
                            <li>
                                <div class="img">
                                    <img src="assets/img/place-list/listing-blog.jpg" alt="Images">
                                </div>

                                <div class="content">
                                    <span>Hotel</span>
                                    <h4>Real Estate Is Being</h4>
                                    <p><i class="flaticon-cursor"></i> 8 BRIGHT ST, JERSEY CITY 07302, USA</p>
                                </div>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="assets/img/place-list/listing-blog2.jpg" alt="Images">
                                </div>

                                <div class="content">
                                    <span>Residence</span>
                                    <h4>Place Of Expectation</h4>
                                    <p><i class="flaticon-cursor"></i> 70 BRIGHT ST NEW YORK USA</p>
                                </div>
                            </li>
                            <li>
                                <div class="img">
                                    <img src="assets/img/place-list/listing-blog3.jpg" alt="Images">
                                </div>

                                <div class="content">
                                    <span>Place</span>
                                    <h4>Came In The Place</h4>
                                    <p><i class="flaticon-cursor"></i> 78 HillStreet ST NEW YORK USA</p>
                                </div>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
