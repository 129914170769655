import { Component, OnInit } from '@angular/core';
import { Blog } from '../../model/blog';
import { AddCommentService } from '../../services/comments.service';
import { PostsService } from '../../services/posts.service';
import { FormGroup } from '@angular/forms';
import { Account_Model } from '../../model/common_model';
import { Profile } from '../profile/profile';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertifyService } from '../../services/alertify.service';
import { SystemService } from '../../services/system.service';
import { ProfileService } from '../../services/profileService';

@Component({
  selector: 'app-home-user',
  templateUrl: './home-user.component.html',
  styleUrls: ['./home-user.component.scss']
})
export class HomeUserComponent implements OnInit {

    commentCount: any;
    userName = localStorage.getItem('token');
    userEmail = localStorage.getItem('email');
    posts: Blog[];
    postCount: number;
    ProfileForm: FormGroup;
    Profiles: any;
    selectedProfile: Profile | null = null;
    fileName: any;
    public imageUrl: string = "../../../../assets/profile.png";
    public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    Account: Account_Model;
    UserId: string;
    type: string;
    displayName:string;

    constructor(private commentService: AddCommentService,
                private postService: PostsService,public service: SystemService, private router: Router, private alertify: AlertifyService, private ProfileService: ProfileService, private route: ActivatedRoute) { }

    async ngOnInit() {
        this.displayName = localStorage.getItem('username');
        this.UserId = this.service.Account.userId;
        try {
            if (this.UserId) {
                console.log(this.UserId);
                let profileData = await this.ProfileService.getProfileByUserId(this.UserId);
                console.log(JSON.stringify(profileData));
                this.Profiles = profileData;
                profileData = this.convertKeysToCamelCase(profileData);
                console.log(JSON.stringify(profileData));
                this.imageUrl = profileData.ProfilePhoto;
            }
            else {
                this.alertify.error("Please update the profile.");
            }
        }
        catch {

            this.alertify.error("Please update the profile.");

        }



    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

}
