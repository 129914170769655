import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Blog } from '../../model/blog';
import { AddCommentService } from '../../services/comments.service';
import { AlertifyService } from '../../services/alertify.service';
import { BlogBannerService } from '../../services/blogBanner.service';
import { CommentBoxService } from '../../services/commentBox.service';
import { PostsService } from '../../services/posts.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

    public postId: number;
    post: Blog;
    userSubmitted: boolean;
    addCommentForm: FormGroup;
    comment: Comment;
    comments: any[];
    currentUser = localStorage.getItem('token');
    isEdit = false;
    newTitle: string;
    newDescription: string;
    loggedinUser: string;
    admin: string;

      constructor(private route: ActivatedRoute,
                  private postsService: PostsService,
                  private addCommentService: AddCommentService,
                  private fb: FormBuilder,
                  private commentBoxJS: CommentBoxService,
                  private blogaBannerService: BlogBannerService,
                  private alertify: AlertifyService,
                  private router: Router) { }

      ngOnInit() {
        this.commentBoxJS.commentBox();
        this.CreateAddCommentForm();
        this.postId = +this.route.snapshot.params['id'];

        //fetch post
        this.route.params.subscribe(
          (params) => {
            this.postId = +params['id'];
            console.log(this.postId);
            this.fetchComments();
          }
        );

        //fetch comments
        this.fetchComments();
      }

      edit() {
        this.isEdit = true;
      }

      cancelUpdate() {
        this.isEdit = false;
      }

      updatePost(id: number) {
        // this.postsService.updatePost(id, this.newTitle, this.newDescription).subscribe(
        //   data => {
        //     this.isEdit = false;
        //     this.fetchpost();
        //   }
        // );

      }

      deletePost(id: number) {
        this.postsService.deletePost(id).subscribe(
          data => {
            this.addCommentService.deleteAllComment(id).subscribe(
              comment_data => {
                this.alertify.success("You have deleted the post");
                this.router.navigate(['/home']);
              }
            );
          }
        );
      }

      onSubmit() {
        // this.userSubmitted = true;

        // if(this.addCommentForm.valid) {
        //   this.addCommentService.addComment(this.commentData()).subscribe(
        //     data => {
        //       console.log(data);
        //       this.fetchComments();
        //     }
        //   );
        //   this.addCommentForm.reset();
        //   this.userSubmitted = false;

        // }
      }

      CreateAddCommentForm() {
        this.addCommentForm = this.fb.group({
          CommentBody: [null]
        })
      }

      get CommentBody(){
        return this.addCommentForm.get('CommentBody') as FormControl;
      }

    commentData(): any  {
        const comment: any  = {
          postId: this.postId, // Assuming this.postId is a property of your class or function
          comment: this.CommentBody.value,
          userName: localStorage.getItem('token') || '',
          commentedOn: new Date().toString(),
          // Add other properties from the Comment type as needed
        };

        return comment;
      }

      fetchComments() {
        let comment_data = this.addCommentService.getComments(this.postId);
        console.log(comment_data);
      }

      loggedin() {
        this.loggedinUser = localStorage.getItem('token');
        this.commentBoxJS.commentBox();
        return this.loggedinUser;
      }

      isAdmin() {
        this.admin = localStorage.getItem('admin');
        return this.admin;
      }

      @Input() comment2 : any;
      newComment: string;
      commentId: number;

      updateComment(id: any) {
        this.commentId = Number(id);
        this.addCommentService.updateComment(this.commentId, this.newComment).subscribe(
          data => {
            this.isEdit = false;
            this.newComment = "";
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([this.router.url]);
          });

      }

      deleteComment(id:number) {
        this.addCommentService.deleteComment(id).subscribe(
          data => {
            this.alertify.success("Comment deleted");
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
            this.router.onSameUrlNavigation = 'reload';
            this.router.navigate([this.router.url]);
          }
        )
      }
}
