<app-navbar-style-guest></app-navbar-style-guest>

<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Privacy Policy</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <!-- <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li> -->
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="single-content">
            <h3>PRAVIDLÁ TÝKAJÚCE SA COOKIES</h3>
            <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
                computer if you agree. Cookies contain information that is transferred to your computer's hard drive.
            </p>

        </div>
        <div class="single-content">
            <h3>We use the following cookies</h3>
            <p>Necessary cookies are absolutely essential for the website to function properly. These cookies ensure
                basic functionalities and security features of the website, anonymously.</p>
            <p>Functional cookies help to perform certain functionalities like sharing the content of the website on
                social media platforms, collect feedbacks, and other third-party features.</p>
            <p>Performance cookies are used to understand and analyse the key performance indexes of the website which
                helps in delivering a better user experience for the visitors.</p>
            <p>Analytical cookies are used to understand how visitors interact with the website. These cookies help
                provide information on metrics the number of visitors, bounce rate, traffic source, etc.</p>
            <p>Advertisement cookies are used to provide visitors with relevant ads and marketing campaigns. These
                cookies track visitors across websites and collect information to provide customized ads.</p>
        </div>
        <div class="single-content">
            <h3>PRAVIDLÁ TÝKAJÚCE SA COOKIES</h3>


            <h3> Cookies</h3>
            <p> Naša stránka využíva súbory cookies na zlepšenie fungovania Našej stránky a na zlepšenie výsledkov
                vyhľadávania. Niektoré sekcie našej stránky využívajú cookies aj na to, aby sme vás lepšie poznali a
                mohli vám ponúknuť lepšie individuálne možnosti vyhľadávania.</p>

            <p> Čo sú cookies?
                Súbory cookies sú malé textové súbory obsahujúce názov navštívenej stránky, ich platnosť a
                preddefinovanú hodnotu. Ukladajú sa do priečinku vášho prehliadača. Pri opätovnej návšteve webovej
                stránky, ktorá súbor vytvorila, môžu byť prehliadačom znovu odoslané. Cookies, ktoré používame,
                nepoškodzujú váš počítač ani iné zariadenia používané na prezeranie internetu.
            </p>
            <p> Aké cookies používame?
                Základné – umožňujú používanie základných funkcií ako napríklad prihlásenie registrovaného používateľa
                alebo predvypĺňanie formulárov. Ak tieto cookies zakážete, nemôžeme vám garantovať plnú funkčnosť našich
                stránok.
                • Prevádzkové a funkčné – nevyhnutné – slúžia na poskytovanie služieb alebo zapamätanie nastavení s
                cieľom zaistiť maximálny komfort pri vašej návšteve, a následne na zlepšovanie jej funkčnosti a vzhľadu.
                Tieto cookies o vás nezhromažďujú informácie, ktoré by sa dali použiť pri marketingu, ani si nepamätajú,
                kde ste sa na internete pohybovali, Ak tieto cookies zakážete, nemôžeme vám garantovať plnú funkčnosť
                našich stránok.
                • Reklamné – slúžia na optimalizáciu zobrazovanej reklamy vzhľadom na zvyklosti návštevníka a
                efektívnosť marketingovej komunikácie zadávateľov. Vďaka nim sa vám napríklad nebude zbytočne často
                zobrazovať reklama z oblasti, o ktorú nemáte záujem. Pri týchto cookies využívame riešenia nasledovných
                tretích strán:
                o uviesť aké
                o ...
                o ....
                • Cookies tretích strán – vytvárajú a využívajú poskytovatelia služieb. Tieto služby sú integrované do
                našich stránok, pretože ich považujeme za užitočné a plne bezpečné. Keďže pri týchto cookies sme
                vykonali opatrenia, aby sme zabránili ich zneužitiu (anonymizáciu na strane našich dodávateľov) nepýtame
                si od vás na ich používanie súhlas. Pri týchto cookies využívame riešenia nasledovných tretích strán:
                o Google Analytics,
                o Google AdSense
                o Facebook.
            </p>
            <p> Ako môžem zakázať cookies?
                Ak si neželáte dostávať cookies z našej stránky, máte možnosť nastaviť prehliadač tak, aby vás
                upozornil, keď dostanete súbor cookie, takže sa môžete rozhodnúť, či ho prijmete alebo nie. Dovoľujeme
                si vás však upozorniť, že ak vo svojom prehliadači vypnete „cookies“, nebudete môcť plne využívať
                niektoré z našich stránok.
                Ak by ste mali ku cookies a k tomuto dokumentu akékoľvek otázky, kontaktujte nás mailom na
                ............................
            </p>

        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
