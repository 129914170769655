<app-navbar-style-guest></app-navbar-style-guest>

<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Privacy Policy</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <!-- <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li> -->
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Privacy Policy</li>
            </ul>
        </div>
    </div>
</div>

<div class="privacy-policy-area ptb-100">
    <div class="container">
        <div class="single-content">
            <h3>Contact Us</h3>
            <p>If you would like to exercise one of your rights as set out above, or you have a question or a complaint
                about this policy, the way your personal information is processed, please contact us by one of the
                following means: hello&#64;atworkbee.com</p>

        </div>
        <div class="single-content">
            <h3>We use the following cookies</h3>
            <p>Necessary cookies are absolutely essential for the website to function properly. These cookies ensure
                basic functionalities and security features of the website, anonymously.</p>
            <p>Functional cookies help to perform certain functionalities like sharing the content of the website on
                social media platforms, collect feedbacks, and other third-party features.</p>
            <p>Performance cookies are used to understand and analyse the key performance indexes of the website which
                helps in delivering a better user experience for the visitors.</p>
            <p>Analytical cookies are used to understand how visitors interact with the website. These cookies help
                provide information on metrics the number of visitors, bounce rate, traffic source, etc.</p>
            <p>Advertisement cookies are used to provide visitors with relevant ads and marketing campaigns. These
                cookies track visitors across websites and collect information to provide customized ads.</p>
        </div>
        <div class="single-content">
            <h3>OCHRANA OSOBNÝCH ÚDAJOV </h3>
            <p> atworkbee.com


                je u prevádzkovateľa PRAMAYO s.r.o., so sídlom Ružová 92, Košice - mestská časť Západ 040 11, IČO: 55
                584 730, zapísanej v Obchodnom registri Mestského súdu Košice, oddiel: Sro, vložka č. 57294/V (ďalej len
                „prevádzkovateľ“) zabezpečená v súlade s požiadavkami Nariadenia Európskeho parlamentu a Rady (EÚ)
                2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o voľnom pohybe
                takýchto údajov, ktorým sa ruší smernica 95/46/ES (všeobecné nariadenie o ochrane údajov) (ďalej aj len
                „Nariadenie“) a zákona č. 18/2018 Z. z. o ochrane osobných údajov a o zmene a doplnení niektorých
                zákonov (ďalej aj len „Zákon“). Záleží nám na ochrane Vašich osobných údajov, preto Vás týmto
                informujeme o tom, ako spracovávame Vaše osobné údaje.
                Ako základný právny rámec nastavenia ochrany osobných údajov u nás bude použité platné a účinné znenie
                Nariadenia EP a Rady č. 2016/679 ako aj znenie zákona č. 18/2018 Z. z. o ochrane osobných údajov. Okrem
                týchto predpisov budú pre tento účel brané na vedomie aj úpravy noriem ISO 27001, ISO 27000, ISO 31000 a
                prípadne ďalšie normy.
                Kontaktné údaje prevádzkovateľa sú
                Adresa: PRAMAYO s.r.o., so sídlom Ružová 92, Košice - mestská časť Západ 040 11, IČO: 55 584 730
                Email: info[at]indiansinkosice.com
                Telefón: +421 950 461 094
                Aké údaje zbierame
                Pri poskytovaní našich služieb a predaji tovaru od Vás ako od dotknutých osôb, zbierame osobné údaje v
                nevyhnutnom rozsahu, za účelom uzatvorenia zmluvného vzťahu a plnenia práv a povinností z neho
                vyplývajúcich. Vaše údaje zbierame na dobu určitú obmedzenú v súlade s príslušnými právnymi predpismi.
                Prevádzkovateľ spracováva osobné údaje, ktoré ste mu poskytli alebo osobné údaje, ktoré prevádzkovateľ
                získal na základe plnenia Vašej objednávky.
                Prevádzkovateľ spracováva Vaše identifikačné a kontaktné údaje a údaje nevyhnutné pre plnenie zmluvy.
                Zákonným dôvodom spracovania osobných údajov je
                • plnenie zmluvy medzi Vami a prevádzkovateľom podľa čl. 6 odst. 1 písm. b) GDPR,
                • spracúvanie je nevyhnutné na plnenie zmluvy, ktorej zmluvnou stranou je dotknutá osoba, alebo aby sa
                na základe žiadosti dotknutej osoby vykonali opatrenia pred uzatvorením zmluvy.
                Práva dotknutých osôb </p>
            <p> Dotknutá osoba má, bez ohľadu na právny základ spracúvania osobných údajov, právo na prístup k osobným
                údajom, právo na ich opravu, vymazanie, obmedzenie spracúvania a právo podať sťažnosť dozornému orgánu,
                ktorým je Úrad na ochranu osobných údajov Slovenskej republiky, alebo právo podať návrh na začatie
                konania o ochrane osobných údajov.
                Právo na prístup k osobným údajom
                Toto právo znamená, že ste oprávnení žiadať od nás potvrdenie o tom, či sa spracovávajú o vás osobné
                údaje, ktoré sa vás týkajú. Ak teda takéto osobné údaje o vás spracúvame máte právo získať prístup k
                svojim osobným údajom a informácie o tom:
                • prečo spracúvame vaše osobné údaje (účel spracovania osobných údajov)
                • aké údaje o vás spracúvame (kategória osobných údajov)
                • komu môžu resp. budú vaše osobné údaje poskytnuté (identifikácia príjemcov alebo okruhu príjemcov)
                • ako dlho budeme vaše osobné údaje uchovávať (doba uchovávania osobných údajov)
                • že máte právo požadovať od nás opravu osobných údajov, ich vymazanie ako aj obmedzenie ich spracovania
                alebo že máte možnosť namietať voči spracovaniu osobných údajov
                • že máte právo podať návrh na začatie konania o ochrane osobných údajov podľa Zákona alebo
                • právo podať sťažnosť dozornému úradu podľa Nariadenia, ktorým je Úrad na ochranu osobných údajov SR
                • odkiaľ pochádzajú vaše osobné údaje (informácie o zdroji), pokiaľ ste nám osobné údaje neposkytli
                priamo vy
                • či využívame automatizované individuálne rozhodovanie vrátane profilovania podľa § 28 ods. 1 a 4
                Zákona; najmä o použitom postupe, ako aj o význame a predpokladaných dôsledkoch takého spracúvania
                osobných údajov pre vás
                • o primeraných zárukách týkajúcich sa prenosu, v prípade, ak vaše osobné údaje prenášame do tretej
                krajiny alebo medzinárodnej organizácie
                Toto právo na prístup pre vás znamená tiež možnosť získať osobné údaje, ktoré o vás spracúvame.
                Poskytneme vám kópiu vašich osobných údajov, ktoré o vás spracúvame. Pokiaľ však budete žiadať opakovane
                o poskytnutie svojich osobných údajov, môžeme od vás žiadať primeraný administratívny poplatok v
                súvislosti s vašou žiadosťou o kópiu svojich osobných údajov.
            </p>
            <p>Právo na opravu osobných údajov
                V rámci podmienok poskytovania našich služieb vás informujeme, aby ste nám poskytovali správne údaje. Ak
                sa však napriek tomu stane, že spracúvame o Vás nesprávne údaje, právo na opravu osobných údajov
                znamená, že ste oprávnení žiadať, aby sme opravili nesprávne osobné údaje, ktoré sa Vás týkajú. Taktiež
                so zreteľom na účel spracovania osobných údaje máte právo aj na to, aby boli doplnené vaše neúplné
                osobné údaje.
            </p>
            <p> Právo na výmaz osobných údajov (právo na zabudnutie)
                Máte právo na to, aby sme vymazali osobné údaje, ktoré sa vás týkajú a zároveň je splnený aspoň jeden z
                nasledovných dôvodov:
                • vaše osobné údaje už nie sú potrebné na účel, na ktorý sme ich získali alebo inak spracúvali
                • odvolali ste svoj súhlas so spracovaním osobných údajov, na základe ktorého my spracúvame vaše osobné
                údaje a zároveň neexistuje iný právny základ pre ich ďalšie spracúvanie
                • namietate voči spracúvaniu osobných údajov, ktoré je vykonávané na právnom základe verejného záujmu
                alebo oprávneného záujmu a neprevažujú žiadne oprávnené dôvody na ich spracúvanie
                • vaše osobné údaje sa spracúvali nezákonne
                • vaše osobné údaje musia byť vymazané, nakoľko je nutné splniť povinnosť podľa práva Slovenskej
                republiky alebo práva Európskej únie
                • vaše osobné údaje sa získavali v súvislosti s ponukou služieb informačnej spoločnosti podľa § 15 ods.
                1 Zákona
                Vášmu právu na výmaz osobných údajov však v konkrétnom prípadne s ohľadom na konkrétne okolnosti nemusí
                byť vyhovené, pokiaľ je spracovanie osobných údajov potrebné na:
                • na uplatnenie nášho práva na slobodu prejavu alebo práva na informácie
                • na splnenie zákonnej povinnosti
                • na uplatnenie nášho právneho nároku
                • na účel archivácie, na účel historického výskumu alebo na štatistický účel, pokiaľ je pravdepodobné,
                že právo na výmaz by nám znemožnilo alebo závažným spôsobom sťažilo dosiahnutie cieľov takého
                spracúvania
                Právo na obmedzenie spracúvania osobných údajov
                Máte právo na to, aby sme obmedzili spracúvanie osobných údajov, ktoré sa vás týkajú a zároveň je
                splnený aspoň jeden z nasledovných dôvodov:
                • počas obdobia umožňujúceho nám overenie správnosti vašich osobných údajov, napadnete správnosť svojich
                osobných údajov
                • pôjde o nezákonné spracúvanie osobných údajov, namietnete výmaz osobných údajov a budete žiadať
                namiesto výmazu obmedzenie spracúvania osobných údajov
                • my už nebudeme potrebovať vaše osobné údaje na žiadny z účelov spracúvania, alebo budete ich
                potrebovať vy na preukázanie, uplatňovanie alebo uplatňovanie svojich právnych nárokov
                • budete namietať voči spracúvaniu osobných údajov a to až do doby overenia, či naše oprávnené záujmy
                prevažujú nad vašimi oprávnenými záujmami
                Právo na prenosnosť osobných údajov
                Ak je naše právo na spracúvanie vašich osobných údajov založené na vašom súhlase alebo na plnení
                záväzkov vyplývajúcich zo zmluvy, ktorú sme s vami uzatvorili, máte právo požiadať o prenos údajov,
                ktoré sa vás týkajú a ktoré ste nám poskytli, inému prevádzkovateľovi osobných údajov.
                Právo na odvolanie súhlasu
                Pokiaľ spracúvame vaše osobné údaje na právnom základe – súhlas dotknutej osoby, máte právo kedykoľvek
                odvolať tento súhlas a to aj pred uplynutím doby, na ktorú bol tento súhlas udelený. Odvolanie súhlasu
                nemá vplyv na zákonnosť spracúvania vychádzajúceho zo súhlasu pred jeho odvolaním.
                Právo namietať
                Ak máte na to dôvody týkajúce sa vašej konkrétnej situácie, máte právo namietať proti spracúvaniu vašich
                osobných údajov, ak je právnym základom pre také spracúvanie náš oprávnený záujem. Ak však náš oprávnený
                záujem na spracúvanie prevyšuje nad vašim osobným záujmom, môžeme pokračovať v spracúvaní vašich údajov,
                a to aj napriek vašej námietke voči spracúvaniu. Taktiež sme oprávnení pokračovať v spracovaní vašich
                údajov, v prípade ak je to potrebné pre preukazovanie, uplatňovanie alebo obhajovanie našich právnych
                nárokov.
            </p>
            <p> Doba uchovávania osobných údajov
                Prevádzkovateľ uchováva osobné údaje
                - po dobu nevyhnutnú k výkonu práv a povinností vyplývajúcich zo zmluvného vzťahu medzi Vami a
                prevádzkovateľom a uplatňovaní nárokov z týchto zmluvných vzťahov (po dobu 10 rokov od ukončenia
                zmluvného vzťahu).
                Po uplynutí doby uchovávania osobných údajov prevádzkovateľ osobné údaje vymaže.
                Ako si uplatniť práva
                Vaše vyššie uvedené práva si môžete uplatniť mailom na info[at]atworkbee.com
                Prosím uveďte vo Vašej žiadosti vaše meno, priezvisko, e-mailovú adresu, prípadne adresu trvalého
                bydliska. Ak nám uvedené údaje neposkytnete, nebude možné vyhovieť vašej žiadosti. Tieto informácie od
                vás požadujeme preto, aby sme si overili vašu totožnosť a neposkytli Vaše osobné údaje neoprávnenej
                osobe.
                V prípade, ak ako dotknutá osoba nemáte spôsobilosť na právne úkony v plnom rozsahu, vaše práva v zmysle
                Nariadenia a Zákona môže uplatniť váš zákonný zástupca. Práva dotknutej osoby, ktorá nežije môže v
                zmysle Nariadenia a Zákona uplatniť blízka osoba. Pokiaľ sú vaše žiadosti ako dotknutej osoby zjavne
                neopodstatnené alebo neprimerané, najmä pre ich opakujúcu sa povahu, môžeme požadovať primeraný poplatok
                zohľadňujúci administratívne náklady na poskytnutie informácií alebo odmietnuť konať na základe
                žiadosti.
                Poskytovanie osobných údajov tretím osobám
                K vašim osobným údajom môžu mať v určitých prípadoch prístup ďalšie osoby, s ktorými spolupracujeme. Pre
                zaistenie konkrétnych spracovateľských operácií, ktoré nedokážeme zaistiť vlastnými silami, využívame
                služby tretích strán. Jedná sa napríklad o tieto tretie strany:
                • ........................
                • ........................
                • DOPLNIŤ
                Je možné, že sa v budúcnosti sa zoznam týchto tretích strán rozšíri o iné osoby, alebo naopak zúži o
                niektoré tretie strany.
                V prípade, ak budeme ako prevádzkovateľ odovzdávať získané osobné údaje o fyzických osobách ďalšej osobe
                v postavení sprostredkovateľa, sme povinní pre tento prípad vyhotoviť osobitnú zmluvu medzi nami a
                každou takouto ďalšou osobou v postavení sprostredkovateľa, alebo inkorporovať príslušné ustanovenia v
                zmysle zákona č. 18/2018 do rámcovej zmluvy, prípadne vyhotoviť dodatok k tejto zmluve.
                Výmaz osobných údajov
                Prevádzkovateľ zabezpečí výmaz poskytnutých osobných údajov z informačného systému po odvolaní súhlasu
                užívateľa na spracovanie osobných údajov, alebo po uplynutí doby na uchovávanie.
                Zodpovedná osoba
                Prevádzkovateľ nemá určenú zodpovednú osobu.
                Zverejňovanie a prenos do tretích krajín
                Vaše osobné údaje sa nezverejňujú a neprenášajú do tretích krajín.
                Profilovanie a automatizované rozhodovanie
                Vaše osobné údaje nepodliehajú automatizovanému rozhodovaniu, ani profilovaniu.
                Bezpečnosť
                Dosiahnutie najvyššej možnej ochrany osobných údajov našich klientov je pre nás vysokou prioritou a
                okrem existujúcich obchodných cieľov bude tejto oblasti venovaná zvýšená pozornosť. Opatrenia technickej
                a organizačnej povahy, ktoré budú použité budú starostlivo vybrané vzhľadom na rozsah spracovania
                osobných údajov a na naše technické a personálne možnosti, ale vždy takým spôsobom, aby tieto
                prostriedky boli maximálne efektívne a aby bolo učinené zadosť platnej a účinnej právnej úprave v SR a
                EÚ.
                Cookies
                Naša stránka využíva súbory cookies na zlepšenie fungovania Našej stránky a na zlepšenie výsledkov
                vyhľadávania. Niektoré sekcie našej stránky využívajú cookies aj na to, aby sme vás lepšie poznali a
                mohli vám ponúknuť lepšie individuálne možnosti vyhľadávania.
                Čo sú cookies?
                Súbory cookies sú malé textové súbory obsahujúce názov navštívenej stránky, ich platnosť a
                preddefinovanú hodnotu. Ukladajú sa do priečinku vášho prehliadača. Pri opätovnej návšteve webovej
                stránky, ktorá súbor vytvorila, môžu byť prehliadačom znovu odoslané. Cookies, ktoré používame,
                nepoškodzujú váš počítač ani iné zariadenia používané na prezeranie internetu.
            </p>
            <p> Aké cookies používame?
                Základné – umožňujú používanie základných funkcií ako napríklad prihlásenie registrovaného používateľa
                alebo predvypĺňanie formulárov. Ak tieto cookies zakážete, nemôžeme vám garantovať plnú funkčnosť našich
                stránok.
                • Prevádzkové a funkčné – nevyhnutné – slúžia na poskytovanie služieb alebo zapamätanie nastavení s
                cieľom zaistiť maximálny komfort pri vašej návšteve, a následne na zlepšovanie jej funkčnosti a vzhľadu.
                Tieto cookies o vás nezhromažďujú informácie, ktoré by sa dali použiť pri marketingu, ani si nepamätajú,
                kde ste sa na internete pohybovali, Ak tieto cookies zakážete, nemôžeme vám garantovať plnú funkčnosť
                našich stránok.
                • Reklamné – slúžia na optimalizáciu zobrazovanej reklamy vzhľadom na zvyklosti návštevníka a
                efektívnosť marketingovej komunikácie zadávateľov. Vďaka nim sa vám napríklad nebude zbytočne často
                zobrazovať reklama z oblasti, o ktorú nemáte záujem. Pri týchto cookies využívame riešenia nasledovných
                tretích strán:
                o uviesť aké
                o ...
                o ....
                • Cookies tretích strán – vytvárajú a využívajú poskytovatelia služieb. Tieto služby sú integrované do
                našich stránok, pretože ich považujeme za užitočné a plne bezpečné. Keďže pri týchto cookies sme
                vykonali opatrenia, aby sme zabránili ich zneužitiu (anonymizáciu na strane našich dodávateľov) nepýtame
                si od vás na ich používanie súhlas. Pri týchto cookies využívame riešenia nasledovných tretích strán:
                o Google Analytics,
                o Google AdSense
                o Facebook.
            </p>
            <p> Ako môžem zakázať cookies?
                Ak si neželáte dostávať cookies z našej stránky, máte možnosť nastaviť prehliadač tak, aby vás
                upozornil, keď dostanete súbor cookie, takže sa môžete rozhodnúť, či ho prijmete alebo nie. Dovoľujeme
                si vás však upozorniť, že ak vo svojom prehliadači vypnete „cookies“, nebudete môcť plne využívať
                niektoré z našich stránok.
            </p>
            <p> Ako podať sťažnosť?
                Ako dotknutá osoba máte právo podať sťažnosť dozornému orgánu, ktorým je Úrad na ochranu osobných údajov
                Slovenskej republiky, so sídlom Hraničná 12, 820 07 Bratislava, tel. č. 02/323 132 14, email:
                statny.dozor[at]pdp.gov.sk, pri podozrení, že sa vaše osobné údaje spracúvajú v rozpore s platnou
                legislatívou.

                Tieto pravidlá nadobúdajú platnosť a účinnosť dňom 21.08.2023
            </p>

        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
