import { Category } from './../../model/category';
import { Component, OnInit } from '@angular/core';
import { CategoryService } from '../../services/category.service';
import { TypeService } from '../../services/type.services';
import { SystemService } from '../../services/system.service';
import { tr } from 'date-fns/locale';

@Component({
    selector: 'app-listing',
    templateUrl: './listing.component.html',
    styleUrls: ['./listing.component.scss']
})
export class ListingComponent implements OnInit {
    categotyList: any;
    categoryIds: any;
    selectedCategoryId: any;
    citiesList: any;
    locationList: any;
    selectedtypeId: any;
    selectedcityId: any;
    selectedlocationId: any;
    searchConfigList: any;
    MainObjectsValues: any;
    SubObjectsValues: any;
    groupedData: any;
    groupedFilters: any;
    typelist: any;
    typeCardlist: any;
    isLoading: boolean = true;
    searchTerm: string = ''; // Property to store the search term
    filteredTypeCardlist: any[] = []; // Array to store filtered results
    selectedFilters: string[] = [];
    item: any;
    isLoadingGrid: boolean = true;
    apiPath: any;
    constructor(private categoryService: CategoryService, private typeService: TypeService, public service: SystemService,) { }

    ngOnInit(): void {
        this.apiPath = this.service.Settings.Base_API_URL;
        this.isLoading = true;
        this.loadCategories();
        console.log(this.isLoading);
        this.loadtypecardlist();
        console.log(this.isLoading);
        this.loadgetCitylist();
        console.log(this.isLoading);
        this.loadgetlocationlist();
        console.log(this.isLoading);


    }


    loadCategories() {
        this.isLoading = true;
        console.log(this.isLoading);
        this.categoryService.getAllCategories().then(categories => {
            console.log(JSON.stringify(categories));
            this.categotyList = categories;
        });
        this.isLoading = false;
    }

    loadgetCitylist() {
        this.isLoading = true;
        this.typeService.getAllTypeCitieslist().then(citiesList => {
            console.log(JSON.stringify(citiesList));
            this.citiesList = citiesList;
        });
        this.isLoading = false;
    }

    loadgetlocationlist() {
        this.isLoading = true;
        console.log(this.isLoading);
        this.typeService.getAllTypelocationlist().then(locationList => {
            console.log(JSON.stringify(locationList));
            this.locationList = locationList;
        });
        this.isLoading = false;
    }

    loadgetSearchConfiglist(categoryId) {
        console.log("categoryId:" + categoryId)
        this.selectedCategoryId = categoryId;
        this.isLoading = true;
        console.log(this.isLoading);
        this.typeService.getAllGetSearchconfiglist().then(searchConfigList => {
            console.log(JSON.stringify(searchConfigList));
            this.searchConfigList = searchConfigList;

            // console.log(JSON.stringify(this.searchConfigList));
            // const MainObjects: { searchId: number; filterHeaderName: string }[] = this.searchConfigList.map(item => ({
            //     searchId: item.searchId,
            //     filterHeaderName: item.filterHeaderName,
            // }));
            // console.log(JSON.stringify(MainObjects));
            // const SubObjects: { searchId: number; filterValue: string }[] = this.searchConfigList.map(item => ({
            //     searchId: item.searchId,
            //     filterValue: item.filterValue,
            // }));
            // console.log(JSON.stringify(SubObjects));
            // this.MainObjectsValues = MainObjects;
            // this.SubObjectsValues = SubObjects;

            // console.log(JSON.stringify(this.MainObjectsValues));
            // console.log(JSON.stringify(this.SubObjectsValues));

            // Use reduce to group by filterHeaderName
            //const groupedData: { [key: string]: FilterCls[] } = this.searchConfigList.reduce((acc, obj) => {
            const groupedData: { [key: string]: FilterCls[] } = this.searchConfigList
                .filter(obj => obj.categoryId.split(',').includes(categoryId.toString())) // Split and check if the categoryId is in the array
                .reduce((acc, obj) => {
                    const key = obj.filterHeaderName;
                    console.log(`Processing entry: ${JSON.stringify(obj)}, key: ${key}`);
                    console.log("********************" + JSON.stringify(acc));
                    if (!acc[key]) {
                        acc[key] = [];
                    }
                    console.log("********************" + JSON.stringify(acc));
                    // //acc[key].push(obj);
                    // if (!acc[key].some(item => item.someProperty === obj.someProperty)) {
                    //     acc[key].push(obj);
                    // }
                    // Check if the entry already exists based on filterValue
                    const existingEntry = acc[key].find(item => item.filterValue === obj.filterValue);

                    if (!existingEntry) {
                        acc[key].push(obj);
                    }
                    console.log("********************" + JSON.stringify(acc));
                    return acc;
                }, {});
            this.groupedData = groupedData;
            console.log(JSON.stringify(groupedData));

        });
        this.isLoading = false;
    }


    async loadtypecardlist() {

        this.isLoadingGrid = true;
        console.log(this.isLoading);
        this.typeCardlist = await this.typeService.geAllTypeCitieslistCall();
        console.log(JSON.stringify(this.typeCardlist));
        try {
            this.typeCardlist.forEach(card => {
                card.categoryNames = [];

                // Split the comma-separated string into an array of category IDs
                const cardCategoryIds = card.categoryId.split(',');
                console.log(cardCategoryIds);
                // Find all matching categories for each category ID in the array
                cardCategoryIds.forEach(cardCategoryId => {
                    console.log(cardCategoryId);
                    console.log(JSON.stringify(this.categotyList));
                    if (Number(cardCategoryId)) {
                        const matchedCategory = this.categotyList.find(category => category.categoryId === Number(cardCategoryId));
                        console.log(matchedCategory);
                        if (matchedCategory) {
                            // Add the matching category name to the array
                            //card.categoryNames.push(matchedCategory.categoryName);
                            card.categoryNames += ` ${matchedCategory.categoryName}`;
                        } else {
                            console.warn(`No matching category found for categoryId: ${cardCategoryId}`);
                            // You can choose to set a default category name or handle this case differently
                        }
                    }
                });
            });
        }
        catch
        {
            console.warn(`Error`);
        }
        console.log(this.typeCardlist);
        this.filteredTypeCardlist = this.shuffleArray(this.typeCardlist);
        this.isLoadingGrid = false;
    }
    shuffleArray(array: any[]): any[] {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    // Function to get keys of the groupedFilters object
    getFilterHeaderNames(): string[] {
        return Object.keys(this.groupedData);
    }

    //******************************************SEARCH********************************************/
    onSearchTermChange(): void {
        // Update the filtered list based on the search term
        console.log(JSON.stringify(this.typeCardlist));
        this.filteredTypeCardlist = this.typeCardlist.filter(obj =>
            obj.typeTitle.toLowerCase().includes(this.searchTerm.toLowerCase())
        );
    }

    //******************************************SEARCH********************************************/
    // onCategoryChange(event): void {
    //     // Update the filtered list based on the selected category IDs
    //     console.log(Array.isArray(event));
    //     console.log(event);
    //     if (this.typeCardlist && this.typeCardlist.length > 0) {
    //         if (event.length > 0) {
    //             this.filteredTypeCardlist = this.typeCardlist.filter(obj =>
    //                 event.toString().includes(obj.categoryId)
    //             );
    //         } else {
    //             // If no category is selected, show all items
    //             this.filteredTypeCardlist = [...this.typeCardlist];
    //         }
    //     }
    // }

    // onLocationChange(event): void {
    //     // Update the filtered list based on the selected category IDs
    //     console.log(Array.isArray(event));
    //     console.log(event);
    //     if (this.typeCardlist && this.typeCardlist.length > 0) {
    //         if (event.length > 0) {
    //             this.filteredTypeCardlist = this.typeCardlist.filter(obj =>
    //                 event.includes(obj.location)
    //             );
    //         } else {
    //             // If no category is selected, show all items
    //             this.filteredTypeCardlist = [...this.typeCardlist];
    //         }
    //     }
    // }

    // onCityChange(event): void {
    //     // Update the filtered list based on the selected category IDs
    //     console.log(Array.isArray(event));
    //     console.log(event);
    //     if (this.typeCardlist && this.typeCardlist.length > 0) {
    //         if (event.length > 0) {
    //             this.filteredTypeCardlist = this.typeCardlist.filter(obj =>
    //                 event.includes(obj.city)
    //             );
    //         } else {
    //             // If no category is selected, show all items
    //             this.filteredTypeCardlist = [...this.typeCardlist];
    //         }
    //     }
    // }

    onCategoryChange(event): void {
        this.onSearchChange(event, 'categoryId');
        console.log(this.isLoading);
        this.selectedCategoryId = event;
        this.loadgetSearchConfiglist(event);

    }

    onLocationChange(event): void {
        this.onSearchChange(event, 'location');
    }

    onCityChange(event): void {
        this.onSearchChange(event, 'city');
    }

    onSearchChange(selectedValues, property: string): void {
        console.log(Array.isArray(selectedValues));
        console.log(selectedValues);

        if (this.typeCardlist && this.typeCardlist.length > 0) {
            if (selectedValues.length > 0) {
                this.filteredTypeCardlist = this.typeCardlist.filter(obj =>
                    selectedValues.toString().includes(obj[property])
                );
            } else {
                // If no values are selected, show all items
                this.filteredTypeCardlist = [...this.typeCardlist];
            }
        }
    }

    //******************************************SEARCH********************************************/


    onCheckboxChange(event: any, filterValue: string, key: string): void {
        const isChecked = event.target.checked;
        console.log(isChecked);
        if (isChecked) {
            const groupedDataSearchcheckbox = this.searchConfigList
                .filter(obj => obj.categoryId.split(',').includes(this.selectedCategoryId.toString())) // Split and check if the categoryId is in the array
                .filter(obj => obj.filterValue.includes(filterValue)); // Filter by filterValue
            console.log(JSON.stringify(groupedDataSearchcheckbox));
            console.log('Search triggered with searchConfigList:', JSON.stringify(this.searchConfigList));
            console.log('Search triggered with groupedData:', JSON.stringify(groupedDataSearchcheckbox));
            console.log('Search triggered with selectedCategoryId:', this.selectedCategoryId);
            console.log('Search triggered with filterValue:', filterValue);
            console.log('Search triggered with key:', key);
            const typeId = this.searchTypeId(groupedDataSearchcheckbox, filterValue);
            console.log(JSON.stringify(typeId));
            if (this.typeCardlist && this.typeCardlist.length > 0) {
                if (typeId) {
                    this.filteredTypeCardlist = this.typeCardlist.filter(obj =>
                        typeId.toString().includes(obj.typeId)
                    );
                } else {
                    //this.filteredTypeCardlist = [...this.typeCardlist];
                    this.onCategoryChange(this.selectedCategoryId);
                }
            }
        }
        else {
            this.onCategoryChange(this.selectedCategoryId);
            //this.filteredTypeCardlist = [...this.typeCardlist];
        }
    }

    //******************************************searchCategoryId********************************************/

    searchCategoryId(categoryId: string): boolean {
        console.log(categoryId);
        // Function to check if the categoryId matches the filter
        if (this.selectedCategoryId === null) {
            return true; // No categoryId filter applied, return true for all items
        }

        const categoryIds = categoryId.split(',').map(id => id.trim()); // Split and trim categoryIds
        return categoryIds.includes(this.selectedCategoryId.toString()); // Check if selectedCategoryId is in the array
    }

    categoryIdFilterApplied(): boolean {
        // Function to check if the categoryId filter is applied
        return this.selectedCategoryId !== null;
    }

    //******************************************searchTypeId********************************************/

    searchTypeId(data: FilterData[], searchFilter: string): string | null {
        const matchingTypeIds: number[] = [];

        for (const item of data) {
            console.log(item);
            const typeId = item.typeId;
            console.log(typeId);
            const filterValue = item.filterValue;
            console.log(filterValue);
            matchingTypeIds.push(Number(typeId));

        }

        if (matchingTypeIds.length > 0) {
            // Use join to concatenate typeIds with a comma
            return matchingTypeIds.join(',');
        } else {
            return null; // Return null if no match is found
        }
    }



    //******************************************SEARCH********************************************/

}

interface FilterData {
    [key: string]: {
        typeId: number;
        filterValue: string;
    }[];
}

interface FilterCls {
    typeId: number;
    filterValueId: number;
    searchId: number;
    filterNameId: number;
    createdDate: string;
    typeTitle: string;
    filterValue: string;
    filterHeaderName: string;
}
