<app-navbar-style-guest *ngIf="isGuest"></app-navbar-style-guest>
<app-home-guest *ngIf="isGuest"></app-home-guest>
<app-footer-style-two *ngIf="isGuest"></app-footer-style-two>

<!-- <app-navbar-style-user *ngIf="isUser"></app-navbar-style-user>
<app-home-user *ngIf="isUser"></app-home-user>
<app-footer-style-two *ngIf="isUser"></app-footer-style-two>


<app-navbar-style-admin *ngIf="isAdmin"></app-navbar-style-admin>
<app-home-admin *ngIf="isAdmin"></app-home-admin>
<app-footer-style-two *ngIf="isAdmin"></app-footer-style-two> -->
