import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Question, QuestionnaireResponse, questionnaireResponse } from '../../model/question';
import { QuestionnaireService } from '../../services/questionnaire.service';

@Component({
    selector: 'app-single-option',
    templateUrl: './single-option.component.html',
    styleUrls: ['./single-option.component.scss']
})
export class SingleOptionComponent implements OnInit {

    @Input()
    question: questionnaireResponse;

    @Output()
    scrollNext = new EventEmitter<any>();

    /**
     * Selected Option
     */
    selectedOption: any

    constructor(private dataService: QuestionnaireService) {
        //console.log(JSON.stringify(this.question));
    }

    ngOnInit() {

    }

    addToSelectedOptions(option) {
        option.questionnaireId = this.question.questionnaireId;
        this.dataService.addDataQuestions(option);
        console.log(JSON.stringify(option));
    }

    /**
     * Go to next Question
     */
    gotoNext() {
        try {
            const jumps = this.question.jumps;
            let destination;
            // console.log(jumps);
            if (jumps.length > 0) {
                jumps.forEach(jump => {
                    if (jump.conditionValue[0].value === this.selectedOption) {
                        destination = jump.destination.id;
                    }
                });
            }
            if (this.scrollNext) {
                this.scrollNext.emit({
                    question: this.question,
                    destination: destination || ''
                });
            } else {
                console.error("this.scrollNext is null or undefined");
            }
        }
        catch
        {
            console.log("Final Single");
        }
    }
}
