<app-navbar-style-guest></app-navbar-style-guest>

<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Product List</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Product List</li>
            </ul>
        </div>
    </div>
</div>

<div class="product-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/products-details"><img src="assets/img/products/product1.jpg" alt="Images"></a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$35.00</del></li>
                            <li>$30.00</li>
                        </ul>
                        <a routerLink="/products-details"><h3>Ham Salad</h3></a>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/products-details"><img src="assets/img/products/product2.jpg" alt="Images"></a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$25.00</del></li>
                            <li>$20.00</li>
                        </ul>
                        <a routerLink="/products-details"><h3>Fresh Cappuccino</h3></a>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/products-details"><img src="assets/img/products/product3.jpg" alt="Images"></a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$45.00</del></li>
                            <li>$40.00</li>
                        </ul>
                        <a routerLink="/products-details"><h3>Delicious Meat</h3></a>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/products-details"><img src="assets/img/products/product4.jpg" alt="Images"></a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$15.00</del></li>
                            <li>$10.00</li>
                        </ul>
                        <a routerLink="/products-details"><h3>Veggie Burger</h3></a>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/products-details"><img src="assets/img/products/product5.jpg" alt="Images"></a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$20.00</del></li>
                            <li>$15.00</li>
                        </ul>
                        <a routerLink="/products-details"><h3>Honey Cake</h3></a>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/products-details"><img src="assets/img/products/product6.jpg" alt="Images"></a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <ul>
                            <li>$15.00</li>
                        </ul>
                        <a routerLink="/products-details"><h3>Delicious Pizza</h3></a>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/products-details"><img src="assets/img/products/product7.jpg" alt="Images"></a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$30.00</del></li>
                            <li>$25.00</li>
                        </ul>
                        <a routerLink="/products-details"><h3>Delicious Dish</h3></a>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="product-card">
                    <a routerLink="/products-details"><img src="assets/img/products/product8.jpg" alt="Images"></a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$40.00</del></li>
                            <li>$35.00</li>
                        </ul>
                        <a routerLink="/products-details"><h3>Chuchi Shrimp</h3></a>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To cart</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="product-card">
                    <a routerLink="/products-details"><img src="assets/img/products/product9.jpg" alt="Images"></a>
                    <div class="product-content">
                        <ul class="rating">
                            <li>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </li>
                        </ul>
                        <ul>
                            <li><del>$30.00</del></li>
                            <li>$25.00</li>
                        </ul>
                        <a routerLink="/products-details"><h3>Chocolate Mousse</h3></a>
                        <a routerLink="/cart" class="default-btn product-btn"><i class='bx bx-cart'></i> Add To cart</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
