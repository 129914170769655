
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Profile } from '../../model/profile';
import { AlertifyService } from '../../services/alertify.service';
import { ProfileService } from '../../services/profileService';
import { ActivatedRoute, Router } from '@angular/router';
import { Account_Model } from '../../model/common_model';
import { SystemService } from '../../services/system.service';
import { format } from 'date-fns';

@Component({
    selector: 'app-profile-form',
    templateUrl: './profile.html',
    styleUrls: ['./profile.css'],
})
export class ProfileFormComponent implements OnInit {
    ProfileForm: FormGroup;
    Profiles: any;
    selectedProfile: Profile | null = null;
    fileName: any;
    public imageUrl: string = "../../../../assets/profile.png";
    public allowedExtensions: Array<string> = ["png", "jpg", "jpeg", "gif", "bmp"];
    Account: Account_Model;
    UserId: string;
    type: string;
    isLoading: boolean = true;

    constructor(private fb: FormBuilder, public service: SystemService, private router: Router, private alertify: AlertifyService, private ProfileService: ProfileService, private route: ActivatedRoute) {
        this.loadForm();
        this.service.HasAccountData.then(() => {
            this.Account = this.service.getUserAccountData();
            console.log(JSON.stringify(this.Account));
            console.log(this.Account.userId);
        });
        console.log(JSON.stringify(this.service.App.getCookie("Bearer")))
        if (!this.service.App.getCookie("Bearer")) {
            console.log(this.service.App.getCookie("Bearer"));
            localStorage.clear();
            this.service.resetPromise();
            this.service.App.setCookie("Bearer", "", 0, "");
            localStorage.removeItem('isAdmin');
            this.Account = <Account_Model>{ UserID: 0, UserName: "" };
            console.log("redirectToLogin");
            this.service.redirectToLogin();
        }
    }

    async ngOnInit() {
        this.isLoading = true;
        this.UserId = this.service.Account.userId;
        try {
            if (this.UserId) {
                console.log(this.UserId);
                let profileData = await this.ProfileService.getProfileByUserId(this.UserId);
                console.log(JSON.stringify(profileData));
                this.Profiles = profileData;
                profileData = this.convertKeysToCamelCase(profileData);
                console.log(JSON.stringify(profileData));
                this.ProfileForm.patchValue(profileData);
                this.imageUrl = profileData.ProfilePhoto;
                this.type = "Update";
                this.loadProfiles(this.UserId);
            }
            else {
                this.type = "Save";
                this.alertify.error("Please update the profile.");
            }
        }
        catch {
            this.type = "Save";
            this.alertify.error("Please update the profile.");

        }
        this.UserId = this.service.Account.userId;
        this.isLoading = false;
    }

    convertKeysToCamelCase(obj: any): any {
        if (obj === null || typeof obj !== 'object') {
            return obj;
        }

        if (Array.isArray(obj)) {
            return obj.map((item) => this.convertKeysToCamelCase(item));
        }

        return Object.keys(obj).reduce((acc, key) => {
            const camelCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
            acc[camelCaseKey] = this.convertKeysToCamelCase(obj[key]);
            return acc;
        }, {});
    }

    loadForm() {
        this.ProfileForm = this.fb.group({
            ProfileId: 0,
            Email: ['', Validators.required],
            PhoneNo: ['', Validators.required],
            ProfilePhoto: ['', Validators.required],
            Address: ['', Validators.required],
            Address2: [''],
            Street: ['', Validators.required],
            City: ['', Validators.required],
            State: ['', Validators.required],
            Country: ['', Validators.required],
            Nationality: ['', Validators.required],
            Gender: ['', Validators.required],
            DOB: ['', Validators.required],
            ID_No: [''],
            Createdby: [''],
            CreatedDate: [''],
            Updatedby: [''],
            UpdatedDate: [''],
            Description: [''],
            Aboutyou: [''],
            Extra1: [''],
            Extra2: [''],
            Extra3: [''],
            Extra4: [''],
            Extra5: [''],
            Extra6: [''],
            Extra7: [''],
            Extra8: [''],
            Extra9: [''],
            Extra10: [''],
            isActive: [''],
            Status: [''],

        });
    }

    ProfilePictureName: string = "";
    fileChange(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    this.ProfilePictureName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {

            }
        }
    }

    loadProfiles(UserId: any) {
        console.log(this.UserId);


    }

    loadProfilesUserId(UserId: any) {
        let res = this.ProfileService.getProfileByUserId(UserId);
        console.log(JSON.stringify(res));
        return res;
    }

    async onSubmit() {
        const Profile: Profile = this.ProfileForm.value;
        if (Profile.ProfileId) {
            // Update existing Profile
            console.log(this.Account.userId);
            Profile.Extra1 = this.Account.userId;
            Profile.ProfilePhoto = this.imageUrl;
            const currentDate = new Date();
            // Format the date using date-fns
            const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
            //Profile.CreatedDate = formattedDate;
            console.log(JSON.stringify(Profile));
            Profile.UpdatedDate = formattedDate;
            Profile.isActive = true;
            Profile.Status = true;
            let res = this.ProfileService.updateProfile(Profile);
            if (res) {
                console.log(res);
                let profileData = await this.ProfileService.getProfileByUserId(this.UserId);
                console.log(JSON.stringify(profileData));
                this.Profiles = profileData;
                profileData = this.convertKeysToCamelCase(profileData);
                console.log(JSON.stringify(profileData));
                this.ProfileForm.patchValue(profileData);
                this.imageUrl = profileData.ProfilePhoto;
                this.type = "Update";
                //this.loadProfilesUserId(res); // Refresh the Profile list
                //this.router.navigate(['/']);
            }

        } else {
            // Add new Profile
            // Update existing Profile
            console.log(this.Account.userId);
            Profile.Extra1 = this.Account.userId;
            Profile.ProfilePhoto = this.imageUrl;
            const currentDate = new Date();
            // Format the date using date-fns
            const formattedDate = currentDate.toISOString().slice(0, 19).replace(' ', 'T');
            Profile.CreatedDate = formattedDate;
            console.log(JSON.stringify(Profile));
            Profile.UpdatedDate = formattedDate;
            Profile.isActive = true;
            Profile.Status = true;
            let res = this.ProfileService.addProfile(Profile);
            if (res) {
                console.log(res);
                let profileData = await this.ProfileService.getProfileByUserId(this.UserId);
                console.log(JSON.stringify(profileData));
                this.Profiles = profileData;
                profileData = this.convertKeysToCamelCase(profileData);
                console.log(JSON.stringify(profileData));
                this.ProfileForm.patchValue(profileData);
                this.imageUrl = profileData.ProfilePhoto;
                this.type = "Update";
                this.loadProfiles(res); // Refresh the Profile list
                //this.router.navigate(['/']);
            }
        }
        // Reset the form
        this.ProfileForm.reset();
        this.selectedProfile = null;
    }

    onEdit(Profile: Profile) {
        // Load the selected Profile into the form for editing
        this.selectedProfile = Profile;
        this.ProfileForm.patchValue(Profile);
    }

    onDelete(ProfileId: number) {
        // let res = this.ProfileService.deleteProfile(ProfileId);
        // if (res) {
        //     this.loadCategories(); // Refresh the Profile list
        // }
    }

    onFileSelect(event: any) {
        let file = event.target.files[0];
        if (file) {
            let extension = file.name.replace(/^.*\./, '');
            if (this.allowedExtensions.indexOf(extension.toLowerCase()) > -1) {
                var myReader: FileReader = new FileReader();
                myReader.onloadend = (e) => {
                    this.imageUrl = <string>myReader.result;
                    console.log(this.imageUrl);
                    this.fileName = file.name;
                }
                myReader.readAsDataURL(file);
            }
            else {
                this.alertify.error("Kindly provide the required fields");
            }
        }
    }
}


export { Profile };

