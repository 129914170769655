<app-navbar-style-guest></app-navbar-style-guest>



<div class="inner-banner inner-bg4">
    <div class="container">

        <div class="inner-banner-title text-center">
            <h3>{{postdetails.title}}</h3>
            <!-- <p>News pariatur Excepteur sint occaecat iat nulla pariatur Excepteur </p> -->
        </div>

        <div class="banner-list">
            <div class="row align-items-center">
                <div class="col-lg-12 col-md-7">
                    <ul class="list">
                        <li><a href="/blog">Home</a></li>
                        <!-- <li><i class='bx bx-chevron-right'></i></li>
                        <li>Pages</li> -->
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active"><a routerLink="/blog">Blog</a></li>
                        <li><i class='bx bx-chevron-right'></i></li>
                        <li class="active">{{postdetails.title}}</li>
                    </ul>
                </div>

                <!-- <div class="col-lg-6 col-md-5">
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div> -->
            </div>
        </div>
    </div>
</div>

<div class="blog-details-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-article">
                    <div class="article-comment-area">
                        <div *ngIf="imageUrl">
                            <img [src]="this.apiPath + '/Documents/appImages/' + postdetails.bannerImage" alt="Images">
                        </div>

                        <ul class="article-comment">
                            <li>
                                <div class="image">
                                    <img src="assets/img/blog/blog-author.png" alt="Images">
                                </div>

                                <div class="content">
                                    <h3>By {{postdetails.author}}</h3>
                                    <span>{{postdetails.postedOn | date:'longDate' }}</span>
                                </div>
                            </li>

                            <!-- <li>
                                <div class="content-list">
                                    <h3>Views</h3>
                                    <span>{{postdetails.count}}</span>
                                </div>
                            </li> -->

                            <li>
                                <div class="content-list">
                                    <h3>Tags</h3>
                                    <span> {{postdetails.tags}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="article-content">
                        <div *ngIf="!postdetails || postdetails.length === 0">
                            <div class="d-table">
                                <div class="d-table-cell">
                                    <div class="section-title text-center">
                                    <span>Please wait, We're loading some exciting content for you</span>
                                    </div>
                                    <div class="spinner" style="background-color: var(--mainColor);"></div>
                                </div>
                            </div>
                        </div>
                        <h2 class="postCardTitle text-capitalize">{{postdetails?.title}}</h2>
                        <p><br></p>
                        <div class="card-text postCardText" [innerHTML]="postdetails.description"></div>
                        <div class="blog-tag">
                            <ul>
                                <li class="active">Tags:</li>
                                <li><a routerLink="/blog"> {{postdetails.tags}}</a></li>
                            </ul>
                        </div>
                        <div *ngIf="isLoading" class="overlay-inner">
                            <i class="fa fa-spinner fa-spin"></i>
                        </div>
                    </div>
                    <div class="comments-form">
                        <div class="contact-form">
                            <!-- <span>Reply</span> -->
                            <h2>Leave a Reply</h2>
                            <!-- <div class="agree-label">
                                <input type="checkbox" id="chb1">
                                <label for="chb1">Login to Comment!</label>
                            </div> -->
                            <div class="agree-label" *ngIf="!isLoggedIn">
                                <label style="color: red;">Login to Comment!</label>
                            </div>
                            <form [formGroup]="commentForm" (ngSubmit)="onSubmit()" *ngIf="isLoggedIn">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="form-group">
                                            <i class='bx bx-envelope'></i>
                                            <!-- Change formControlName to "content" to match the form control name -->
                                            <textarea formControlName="content" class="form-control" cols="30" rows="8" placeholder="Your Message"></textarea>
                                       <!-- Display an error message if the "content" control is invalid -->
                                        <div *ngIf="commentForm.get('content').hasError('required') && (commentForm.get('content').dirty || commentForm.get('content').touched)">
                                            Content is required.
                                        </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="default-btn border-radius">Post A Comment <i class='bx bx-plus'></i></button>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                    <!-- <div class="comments-wrap">
                        <h3 class="title">Comments(02)</h3>
                        <h3 class="title">Comments({{commentdata.length}})</h3>
                        <ul>
                            <li *ngFor="let comment of commentdata">
                                <img src="assets/img/blog/comment-profile1.png" alt="Image">
                                <h3>{{comment.userName}}</h3>
                                <span>{{comment.commentedOn | date:'yyyy-MM-dd'}}</span>
                                <p>{{comment.content}}</p>
                                <button (click)="showReplyFormValue(comment.commentId); showReplyForm = !showReplyForm">Reply</button>
                                <div class="comments-form" *ngIf="showReplyForm">
                                    <div class="contact-form">
                                      <h2>Leave a Reply</h2>
                                      <form [formGroup]="commentForm" (ngSubmit)="onSubmitReply()">
                                         Change formControlName to "content" to match the form control name
                                        <textarea formControlName="content" class="form-control" cols="30" rows="8" placeholder="Your Reply"></textarea>
                                         Display an error message if the "content" control is invalid
                                        <div *ngIf="commentForm.get('content').hasError('required') && (commentForm.get('content').dirty || commentForm.get('content').touched)">
                                          Content is required.
                                        </div>
                                        <button type="submit" class="default-btn border-radius">Post A Reply <i class='bx bx-plus'></i></button>
                                      </form>
                                    </div>
                                  </div>
                            </li>
                        </ul>
                    </div> -->
                    <div class="comments-wrap">
                        <h3 class="title">Comments({{ commentdata.length }})</h3>
                        <ul>
                            <li *ngFor="let comment of commentdata">
                                <div class="comment">
                                    <img src="assets/img/blog/comment-profile1.png" alt="Image">
                                    <h3>{{ comment.userName }}</h3>
                                    <span>{{ comment.commentedOn | date:'yyyy-MM-dd' }}</span>
                                    <p>{{ comment.content }}</p>
                                    <button (click)="showReplyFormValue(comment.commentId); showReplyForm = !showReplyForm" class="default-btn border-radius" style="padding: 0px 27px;">Reply</button>
                                    <!-- Reply form for the parent comment -->
                                    <div class="comments-form" *ngIf="showReplyForm">
                                        <div class="contact-form">
                                            <h2>Leave a Reply</h2>
                                            <form [formGroup]="commentForm" (ngSubmit)="onSubmitReply(comment.commentId)">
                                                <textarea formControlName="content" class="form-control" cols="30" rows="8" placeholder="Your Reply"></textarea>
                                                <div *ngIf="commentForm.get('content').hasError('required') && (commentForm.get('content').dirty || commentForm.get('content').touched)">
                                                    Content is required.
                                                </div>
                                                <button type="submit" class="default-btn border-radius">Post A Reply <i class='bx bx-plus'></i></button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <!-- Display replies for the parent comment -->
                                <ul class="replies" *ngIf="comment.replies && comment.replies.length > 0">
                                    <li *ngFor="let reply of comment.replies">
                                        <div class="reply">
                                            <img src="assets/img/blog/comment-profile1.png" alt="Image">
                                            <h3>{{ reply.userName }}</h3>
                                            <span>{{ reply.commentedOn | date:'yyyy-MM-dd' }}</span>
                                            <p>{{ reply.content }}</p>
                                            <!-- Add reply button and form for each reply if needed -->
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>



                </div>
            </div>


            <div class="col-lg-4">
                <div class="blog-widget-left">
                    <div class="blog-widget ">
                        <h3 class="title">Search</h3>

                        <div class="search-widget">
                            <form class="search-form">
                                <input type="search" class="form-control" placeholder="Search...">
                                <button type="submit"><i class="bx bx-search"></i></button>
                            </form>
                        </div>
                    </div>



                    <div class="blog-widget">
                        <h3 class="title">Recent Posts</h3>

                        <div class="widget-popular-post" *ngFor="let post of filteredTypeCardlistposts">
                            <article class="item">
                                <a [routerLink]="'/listing-details/' + post.id"><img [src]="this.apiPath + '/Documents/appImages/' + post.bannerImage" alt="Images" width="100" height="100"></a>
                                <div class="info">
                                    <time datetime="2020-01-12">{{post.postedOn}}</time>
                                    <h4 class="title-text"><a
                                            routerLink="/blog-details-user-view/{{post.id}}">{{post.title}}</a></h4>
                                    <p>{{ getCategoryOnlyNames(post.category) }}</p>
                                </div>
                            </article>
                        </div>
                    </div>



                    <div class="blog-widget">
                        <h3 class="title">Categories</h3>

                        <div class="widget_categories">
                            <ul *ngFor="let post of categoryCountsArray">
                                <li><a routerLink="/blog">{{ post.name }} <span>({{ post.count }})</span></a></li>
                            </ul>
                        </div>
                    </div>


                </div>
            </div>


        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
