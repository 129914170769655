import { Component, OnInit, Input, EventEmitter, Output, HostListener } from '@angular/core';
import { Question, QuestionnaireResponse, questionnaireResponse } from '../../model/question';
import { QuestionnaireService } from '../../services/questionnaire.service';

@Component({
    selector: 'app-multi-option',
    templateUrl: './multi-option.component.html',
    styleUrls: ['./multi-option.component.scss']
})
export class MultiOptionComponent implements OnInit {

    @Input()
    question: questionnaireResponse;

    @Output()
    scrollNext = new EventEmitter<any>();

    /**
     * Selected Options
     */
    selectedOptions: questionnaireResponse[] = [];

    constructor(private dataService: QuestionnaireService) { }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent): void {
        if (event.keyCode === 13) {
            this.gotoNext();
        }
    }

    ngOnInit() {


    }

    /**
     * Add to list os selected option
     * @param option
     */
    addToSelectedOptions(option) {
        option.questionnaireId = this.question.questionnaireId;
        this.dataService.addDataQuestions(option);
        if (this.selectedOptions.indexOf(option) == -1) {
            this.selectedOptions.push(option);
        }
        console.log(JSON.stringify(option));
    }

    /**
     * Remove Selected Option
     * @param option
     */
    removeOption(option) {
        this.selectedOptions = this.selectedOptions.filter(item => {
            return option !== item;
        });
    }


    /**
     * Go to next question
     */
    gotoNext() {
        try {
            if (this.scrollNext) {
                this.scrollNext.emit({
                    question: this.question,
                    destination: ''
                });
            } else {
                console.error("this.scrollNext is null or undefined");
            }
        }
        catch
        {
            console.log("Final Multi");
        }

    }
}
