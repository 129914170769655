<div class="listing-widget gallery-slider" *ngIf="typeGallerylist">
    <h3 class="title">Gallery / Photos</h3>
    <!-- <div class="gallery-slider-area owl-carousel owl-theme">
        <div class="gallery-item" *ngFor="let obj of typeGallerylist; let i = index">
            <img [src]="sanitizeImage(obj.galleryPhoto)"  alt="Slide {{ i + 1 }}">
        </div>
    </div> -->
<div class="listing-widget gallery-slider">
    <div class="gallery-slider-area"></div>
    <ngb-carousel [activeId]="currentIndex">
        <ng-template ngbSlide *ngFor="let obj of typeGallerylist; let i = index" class="gallery-item">
            <img [src]="sanitizeImage(this.apiPath + '/Documents/appImages/'+obj.galleryPhoto)" alt="Slide {{ i + 1 }}">
        </ng-template>
    </ngb-carousel>
    <!-- <button (click)="prev()">Previous</button>
    <button (click)="next()">Next</button> -->
</div>
</div>
