
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { SystemService } from './system.service';
import { ResponseModel } from '../model/question';






@Injectable({
    providedIn: 'root'
})
export class ResponseService {
    apiUrl: any;
    constructor(private http: HttpClient, public service: SystemService) {
        this.apiUrl = this.service.Settings.Base_API_URL;
    }


    //Add ResponseModels
    async addResponse(data: ResponseModel): Promise<Observable<ResponseModel>> {
        console.log(data);
        let res = await this.service.Data.ExecuteAPI_Post("Response", data);
        console.log(res);
        return res;
    }

    async updateResponse(data: ResponseModel): Promise<Observable<ResponseModel>> {
        let res = await this.service.Data.ExecuteAPI_Put("Response", `${data.Id}?id=${data.Id}`, data);
        return res;
    }

    //Get All ResponseModels
    async getAllResponse(): Promise<ResponseModel[]> {
        try {
          return await this.service.Data.ExecuteAPI_Get<ResponseModel[]>("Response");
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Response:', error);
          return []; // or throw the error, depending on your error-handling strategy
        }
      }
  //By Id ResponseModels
    async getResponseById(id: number): Promise<ResponseModel> {
        try {
          return await this.service.Data.ExecuteAPI_Get<ResponseModel>("Response/" + id);
        } catch (error) {
          // Handle errors here
          console.error('Error fetching Response:', error);
          return null; // or throw the error, depending on your error-handling strategy
        }
      }

      async deleteResponse(id: number) {
        return  await this.service.Data.ExecuteAPI_Delete("Response", `${id}?id=${id}`);
    }

}
