<app-navbar-style-guest></app-navbar-style-guest>

<div class="inner-banner inner-bg7">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Products Details</h3>
            <ul>
                <li><a href="/">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Pages</li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Products Details</li>
            </ul>
        </div>
    </div>
</div>

<section class="shop-detls ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="shop-detls-image">
                    <img src="assets/img/products/product-details.jpg" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="shop-desc">
                    <h3>Ham Salad</h3>

                    <div class="price">
                        <span class="new-price">$35.00</span>
                        <span class="old-price">$30.00</span>
                    </div>
                    <div class="shop-review">
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star-half'></i>
                        </div>
                        <a routerLink="/" class="rating-count">3 reviews</a>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                    <div class="input-count-area">
                        <h3>Quantity</h3>
                        <div class="input-counter">
                            <span class="minus-btn"><i class='bx bx-minus'></i></span>
                            <input type="text" min="1" value="1">
                            <span class="plus-btn"><i class='bx bx-plus'></i></span>
                        </div>
                    </div>
                    <div class="shop-add">
                        <button type="submit" class="default-btn"><i class="fas fa-cart-plus"></i> Buy Now!</button>
                        <button type="submit" class="default-btn"><i class="fas fa-cart-plus"></i> Add To Cart</button>
                    </div>
                    <div class="shop-share">
                        <ul>
                            <li><span>Share:</span></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook' ></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="tab shop-detls-tab">
                    <ul class="tabs">
                        <li><a href="#">Description</a></li>
                        <li><a href="#">Additional information</a></li>
                        <li><a href="#">Reviews</a></li>
                    </ul>

                    <div class="tab_content current active">
                        <div class="tabs_item current">
                            <div class="shop-detls-tab-content">
                                <p>Design inspiration lorem ipsum dolor sit amet, consectetuer adipiscing elit. Morbi commodo, ipsum sed pharetra gravida, orci magna rhoncus neque, id pulvinar odio lorem non turpis. Nullam sit amet enim. Suspendisse id velit vitae ligula volutpat condimentum.  Aliquam erat volutpat. Sed quis velit. Nulla facilisi. Nulla libero. Vivamus pharetra posuere sapien. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Nam consectetuer. Sed aliquam, nunc eget euismod ullamcorper, lectus nunc ullamcorper orci, fermentum bibendum enim nibh eget ipsum. Nulla libero. Vivamus pharetra posuere sapien.</p>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="shop-detls-tab-content">
                                <ul class="additional-information">
                                    <li><span>Brand:</span> ThemeForest</li>
                                    <li><span>Color:</span> Brown</li>
                                    <li><span>Size:</span> Large, Medium</li>
                                    <li><span>Weight:</span> 27 kg</li>
                                    <li><span>Dimensions:</span> 16 x 22 x 123 cm</li>
                                </ul>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="shop-detls-tab-content">
                                <div class="shop-review-form">
                                    <h3>Customer Reviews</h3>
                                    <div class="review-title">
                                        <div class="rating">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star-half'></i>
                                        </div>
                                        <p>Based on 3 reviews</p>
                                        <a href="#" class="default-btn btn-right">Write a Review <span></span></a>
                                    </div>

                                    <div class="review-comments">
                                        <div class="review-item">
                                            <div class="rating">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>June 21, 2020</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                            <a href="#" class="review-report-link">Report as Inappropriate</a>
                                        </div>

                                        <div class="review-item">
                                            <div class="rating">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>June 21, 2020</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                            <a href="#" class="review-report-link">Report as Inappropriate</a>
                                        </div>

                                        <div class="review-item">
                                            <div class="rating">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star-half'></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>June 21, 2020</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                            <a href="#" class="review-report-link">Report as Inappropriate</a>
                                        </div>
                                    </div>

                                    <div class="review-form">
                                        <div class="contact-wrap-form">
                                            <div class="contact-form">
                                                <span>Review</span>
                                                <h2>Write a Review</h2>

                                                <form id="contactForm">
                                                    <div class="row">
                                                        <div class="col-lg-6 col-sm-6">
                                                            <div class="form-group">
                                                                <i class='bx bx-user'></i>
                                                                <input type="text" name="name" class="form-control" required data-error="Please enter first name*" placeholder="First Name*">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6">
                                                            <div class="form-group">
                                                                <i class='bx bx-user'></i>
                                                                <input type="text" name="name" class="form-control" required data-error="Please enter last name" placeholder="Last Name*">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6">
                                                            <div class="form-group">
                                                                <i class='bx bx-mail-send'></i>
                                                                <input type="email" name="email"  class="form-control" required data-error="Please enter your email" placeholder="Email address*">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-6 col-sm-6">
                                                            <div class="form-group">
                                                                <i class='bx bx-copy-alt'></i>
                                                                <input type="text" name="website"  class="form-control" required data-error="Please enter your website" placeholder="Website">
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12">
                                                            <div class="form-group">
                                                                <i class='bx bx-envelope'></i>
                                                                <textarea name="message" class="form-control" id="message" cols="30" rows="8" required data-error="Write your message" placeholder="Your Message"></textarea>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12 col-md-12">
                                                            <button type="submit" class="default-btn default-hot-toddy">Post Comment <i class='bx bx-right-arrow-alt'></i></button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>
